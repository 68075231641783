import 'angularjs-toaster';

export class AlertsService {
    constructor(
        private $q: ng.IQService,
        private toaster: any, // IToasterService has wrong type for pop
    ) {}
    addAlert(message: string, type = 'success', displayTime = 2, retry = false): ng.IPromise<any> {
        if (!message) {
            return;
        }
        let promise = this.$q.defer();
        type = type === 'danger' ? 'error' : type; // fix for angularjs-toaster

        this.toaster.pop({
            type: type,
            body: message,
            directiveData: {
                retry: retry,
            },
            timeout: displayTime > 0 ? displayTime * 1000 + 700 : 0, // account for animation or 0
            showCloseButton: true,
            clickHandler: (toast, kind) => {
                if (kind === 'retry') {
                    promise.resolve();
                    return true;
                } else if (kind === 'close') {
                    return true;
                }
            },
            onHideCallback: () => {
                promise.reject();
            },
        });
        return promise.promise;
    }
}

export default angular.module('mpdx.common.alerts.service', ['toaster']).service('alerts', AlertsService).name;
