import 'angular-gettext';
import * as moment from 'moment';
import { findIndex } from 'lodash/fp';
import { StateParams } from '@uirouter/core';
import accounts, { AccountsService } from '../../../../common/accounts/accounts.service';
import appeals, { AppealsService } from '../../appeals.service';
import appealsFlows, { FlowsService, FlowStatus } from '../flows.service';
import contact, { ContactsService } from '../../../../contacts/contacts.service';
import locale, { LocaleService } from '../../../../common/locale/locale.service';
import modal, { ModalService } from '../../../../common/modal/modal.service';
import uiRouter from '@uirouter/angularjs';

export class ContactController {
    reasonsKeyToString: {};
    contact: any;
    constructor(
        private $stateParams: StateParams,
        private gettext: ng.gettext.gettextFunction,
        private accounts: AccountsService, // used in view
        private appeals: AppealsService,
        private appealsFlows: FlowsService,
        private contacts: ContactsService,
        private locale: LocaleService, // used in view
        private modal: ModalService,
    ) {
        this.reasonsKeyToString = {
            gave_more_than_pledged_range: this.gettext('May have given a special gift in the last 3 months'),
            started_giving_range: this.gettext('May have joined my team in the last 3 months'),
            pledge_amount_increased_range: this.gettext('May have increased their giving in the last 3 months'),
            stopped_giving_range: this.gettext('May have stopped giving for the last 2 months'),
            no_appeals: this.gettext('"Send Appeals?" set to No'),
            pledge_late_by: this.gettext('May have missed a gift in the last 30-90 days'),
        };
    }
    star(): ng.IPromise<any> {
        return this.contacts.star(this.contact).then((data) => {
            this.contact.starred_at = data.starred_at;
        });
    }
    overdue(date: string): boolean {
        return moment(date).isBefore();
    }
    removePledge(pledge: any): ng.IPromise<void> {
        const message = this.gettext(
            'Are you sure you wish to remove this commitment? This contact will be moved to "Asked".',
        );
        return this.modal.confirm(message).then(() => {
            this.appeals.removePledge(pledge.id).then(() => {
                this.appealsFlows.updateColumns(FlowStatus.Asked, this.contact);
            });
        });
    }
    editPledge(pledge: any): ng.IPromise<void> {
        return this.modal
            .open({
                template: require('../../show/editPledge/edit.html'),
                controller: 'editPledgeController',
                locals: {
                    appealId: this.$stateParams.appealId,
                    pledge: { ...pledge, contact: this.contact },
                },
            })
            .then((updatedPledge) => {
                const pledges = this.contact.pledges.slice(0);
                pledges[findIndex(['id', updatedPledge.id], pledges)] = updatedPledge;
                this.contact.pledges = pledges;
                if (updatedPledge.status !== pledge.status) {
                    this.appealsFlows.updateColumns(updatedPledge.status, this.contact);
                }
            });
    }
}

const Contact = {
    template: require('./contact.html'),
    controller: ContactController,
    bindings: {
        contact: '<',
    },
};

export default angular
    .module('mpdx.tools.appeals.flows.contact.component', [
        'gettext',
        uiRouter,
        accounts,
        appeals,
        appealsFlows,
        contact,
        locale,
        modal,
    ])
    .component('appealsFlowsContact', Contact).name;
