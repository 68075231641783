import serverConstants, { ServerConstantsService } from '../../../common/serverConstants/serverConstants.service';
import users, { UsersService } from '../../../common/users/users.service';

class HourToSendNotificationsController {
    constructor(private serverConstants: ServerConstantsService, private users: UsersService) {}
}

const HourToSendNotifications = {
    template: require('./hourToSendNotifications.html'),
    controller: HourToSendNotificationsController,
};

export default angular
    .module('mpdx.preferences.personal.hourToSendNotifications.component', [serverConstants, users])
    .component('preferencesPersonalHourToSendNotifications', HourToSendNotifications).name;
