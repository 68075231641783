import api, { ApiService } from '../../../../common/api/api.service';

interface IExportContactsArguments {
    filters: any;
    format: string;
    mailing?: boolean;
    labelType?: string;
    sort?: string;
}

export class ExportContactsService {
    constructor(private $window: ng.IWindowService, private api: ApiService) {}
    create({ filters, format, mailing, labelType, sort }: IExportContactsArguments): ng.IPromise<void> {
        let pathAddition = '';
        if (mailing) {
            pathAddition = '/mailing';
        }
        return this.api
            .post({
                url: `contacts/exports${pathAddition}`,
                data: {
                    params: {
                        filter: filters,
                        type: labelType,
                        sort: sort,
                    },
                },
                type: 'export_logs',
            })
            .then((data) => {
                let token = this.$window.localStorage.getItem('token');
                this.$window.location.replace(
                    `${process.env.API_URL}/contacts/exports${pathAddition}/${data.id}.${format}?access_token=${token}`,
                );
            });
    }
}

export default angular
    .module('mpdx.contacts.bulkActions.contact.export.service', [api])
    .service('exportContacts', ExportContactsService).name;
