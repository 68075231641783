import analysisFilter, { AnalysisFilterService } from '../filter/filter.service';

export class AnalysisSearchController {
    searchParams: string;
    watcher: () => void;
    constructor($rootScope: ng.IRootScopeService, private analysisFilter: AnalysisFilterService) {
        this.searchParams = '';

        this.watcher = $rootScope.$on('analysisSearchReset', () => {
            this.searchParams = '';
        });
    }
    $onInit(): void {
        this.searchParams = angular.copy(this.analysisFilter.wildcardSearch);
    }
    $onDestroy(): void {
        this.watcher();
    }
    paramChanged(): void {
        this.analysisFilter.wildcardSearch = this.searchParams;
        this.analysisFilter.change();
    }
}

const Search = {
    controller: AnalysisSearchController,
    template: require('./search.html'),
};

export default angular
    .module('mpdx.reports.analysis.search.component', [analysisFilter])
    .component('analysisSearch', Search).name;
