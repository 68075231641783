import * as moment from 'moment';
import api, { ApiService } from '../../../common/api/api.service';

export class ListController {
    data: any[];
    meta: { pagination: { total_count: number } };
    loading: boolean;
    watcher: () => void;
    tab: 'recent' | 'onHand';
    filter: {
        created_at?: string;
        referrer: 'any';
        status?: string;
    };
    constructor(private $rootScope: ng.IRootScopeService, private api: ApiService) {}
    $onInit(): void {
        this.filter =
            this.tab === 'recent'
                ? {
                      created_at: `${moment().subtract(2, 'weeks').format('YYYY-MM-DD')}..${moment().format(
                          'YYYY-MM-DD',
                      )}`,
                      referrer: 'any',
                  }
                : {
                      referrer: 'any',
                      status: 'Never Contacted,Ask in Future,Cultivate Relationship,Contact for Appointment',
                  };
        this.load();
        this.watcher = this.$rootScope.$on('accountListUpdated', () => {
            this.load();
        });
    }
    $onDestroy(): void {
        this.watcher();
    }
    load(): ng.IPromise<void> {
        this.loading = true;
        return this.api
            .get({
                url: 'contacts',
                data: {
                    fields: {
                        contacts: 'name',
                    },
                    filter: {
                        account_list_id: this.api.account_list_id,
                        ...this.filter,
                    },
                    per_page: 3,
                },
            })
            .then((data) => {
                this.loading = false;
                this.data = data;
                this.meta = data.meta;
            });
    }
    totalContacts(): number {
        return this.meta?.pagination?.total_count || 0;
    }
}

const List = {
    template: require('./list.html'),
    controller: ListController,
    bindings: {
        tab: '@',
    },
};

export default angular.module('mpdx.home.referrals.list', [api]).component('homeReferralsList', List).name;
