export class AnalyticsService {
    constructor(private $window: ng.IWindowService, private $document: ng.IDocumentService) {}
    dispatch(event: string, data?: Record<string, string>) {
        this.$window.dataLayer.push({
            event,
            ...data,
        });
    }
}

export default angular.module('mpdx.common.analytics.service', []).service('analytics', AnalyticsService).name;
