import 'angular-gettext';
import contactsTags, { ContactsTagsService } from '../../../../contacts/sidebar/filter/tags/tags.service';
import importDonation, { ImportDonationService } from '../donation.service';
import modal, { ModalService } from '../../../../common/modal/modal.service';
import serverConstants, { ServerConstantsService } from '../../../../common/serverConstants/serverConstants.service';

class PreviewController {
    accept: boolean;
    watcher: () => void;
    accNumberMatched: any;
    accNameMatched: any;
    designationMatched: any;
    paymentMethodMatched: any;
    constructor(
        $rootScope: ng.IRootScopeService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private contactsTags: ContactsTagsService,
        private importDonation: ImportDonationService,
        private modal: ModalService,
        private serverConstants: ServerConstantsService, // used in view
    ) {
        this.accept = false;

        this.watcher = $rootScope.$on('accountListUpdated', () => {
            this.contactsTags.load();
        });
        this.accNumberMatched = {};
        this.accNameMatched = {};
        this.designationMatched = {};
        this.paymentMethodMatched = {};
    }
    $onInit(): void {
        this.contactsTags.load();
    }
    $onDestroy(): void {
        this.watcher();
    }
    save() {
        this.importDonation.data.in_preview = false;
        return this.importDonation.save().then(() => {
            this.importDonation.data = null;
            const message = this.gettextCatalog.getString(
                'Your CSV import has started and your contacts will be in Partner Essentials shortly. We will email you when your import is complete.',
            );
            return this.modal.info(message);
        });
    }
    back(): void {
        this.importDonation.back();
    }
    findAppeal(name: string) {
        let res = this.importDonation.accountListAppeals.includes(name);
        if (res) {
            return name;
        }
        return '';
    }
    getDonationGeneratedID(donationID: string, key: number): string {
        let updateDonation = this.importDonation.generatedIdCollection.find(
            (donation) => donationID === donation.orginalID && donation.key === key,
        );
        if (updateDonation) {
            return updateDonation.id;
        }
        return donationID;
    }
    donationAmount(key: number) {
        let donationObj = {
            amount: '',
            imported: false,
        };
        let donationId = this.importDonation.data.file_constants.donation_id[key];
        let existingDonation = this.importDonation.data.donations.find((donation) => donationId === donation.remote_id);
        if (!existingDonation) {
            donationObj.amount = this.importDonation.data.file_constants[
                this.importDonation.file_headers_mappings.amount
            ][key];
            donationObj.imported = true;
        } else {
            let isUpdateDonation = this.importDonation.generatedIdCollection.find(
                (donation) => donationId === donation.orginalID && donation.key === key,
            );
            if (isUpdateDonation) {
                donationObj.amount = this.importDonation.data.file_constants[
                    this.importDonation.file_headers_mappings.amount
                ][key];
                donationObj.imported = true;
            } else {
                donationObj.imported = false;
                donationObj.amount = existingDonation.amount;
            }
        }
        return donationObj;
    }
}

const Preview: ng.IComponentOptions = {
    controller: PreviewController,
    template: require('./preview.html'),
};

export default angular
    .module('mpdx.preferences.donation.preview.component', [
        'gettext',
        contactsTags,
        importDonation,
        modal,
        serverConstants,
    ])
    .component('importDonationPreview', Preview).name;
