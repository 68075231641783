import 'angular-gettext';
import preferencesOrganizations, { OrganizationsService } from '../organizations.service';

class InviteController {
    saving: boolean;
    email: string;
    constructor(
        private gettextCatalog: ng.gettext.gettextCatalog,
        private preferencesOrganizations: OrganizationsService,
    ) {
        this.saving = false;
        this.email = '';
    }
    sendInvite(): ng.IPromise<void> {
        this.saving = true;
        const successMessage = this.gettextCatalog.getString('Partner Essentials sent an invite to {{email}}', {
            email: this.email,
        });
        const errorMessage = this.gettextCatalog.getString(
            "Partner Essentials couldn't send an invite (check to see if email address is valid)",
        );
        return this.preferencesOrganizations
            .createInvite(this.email, successMessage, errorMessage)
            .then(() => {
                this.saving = false;
                this.email = '';
            })
            .catch((err) => {
                this.saving = false;
                throw err;
            });
    }
}

const Invite = {
    controller: InviteController,
    template: require('./invite.html'),
};

export default angular
    .module('mpdx.preferences.organizations.invite.component', ['gettext', preferencesOrganizations])
    .component('inviteOrganizationsPreferences', Invite).name;
