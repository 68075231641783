import 'angular-gettext';
import { reject } from 'lodash/fp';
import api, { ApiCollection, ApiPagination, ApiService } from '../../../common/api/api.service';
import joinComma from '../../../common/fp/joinComma';
import modal, { ModalService } from '../../../common/modal/modal.service';
import preferencesOrganizations, { OrganizationsService } from '../organizations.service';

export class ContactsController {
    data: ApiCollection<any>;
    meta: ApiPagination;
    listLoadCount: number;
    loading: boolean;
    organizationIds: string[];
    wildcardSearch: string;
    constructor(
        private $q: ng.IQService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private api: ApiService,
        private modal: ModalService,
        private preferencesOrganizations: OrganizationsService,
    ) {
        this.listLoadCount = 0;
        this.loading = false;
        this.organizationIds = [];
        this.wildcardSearch = '';
    }
    $onInit(): void {
        this.load();
    }
    load(page = 1): ng.IPromise<ApiCollection<any>> {
        if (
            this.wildcardSearch === '' ||
            (this.preferencesOrganizations.list.length > 1 && this.organizationIds.length === 0)
        ) {
            return this.$q.resolve(this.data);
        }

        this.loading = true;
        let currentCount;
        this.listLoadCount++;
        currentCount = angular.copy(this.listLoadCount);
        return this.api
            .get({
                url: 'organizations/contacts',
                data: {
                    include:
                        'people,people.email_addresses,people.phone_numbers,addresses,account_list,' +
                        'account_list.account_list_users,account_list.account_list_users.email_addresses',
                    page: page,
                    filter: {
                        organization_id: joinComma(this.organizationIds),
                        wildcard_search: this.wildcardSearch,
                        status: 'active,hidden,null',
                    },
                    fields: {
                        contact: 'name,people,account_list,addresses,allow_deletion,square_avatar',
                        people: 'first_name,last_name,email_addresses,phone_numbers,deceased',
                        email_addresses: 'email,primary,historic',
                        phone_numbers: 'number,primary,historic',
                        account_lists: 'name,account_list_users',
                        account_list_users: 'first_name,last_name,email_addresses',
                        addresses: 'primary_mailing_address,street,city,state,postal_code',
                    },
                },
                method: 'POST',
                overrideGetAsPost: true,
            })
            .then((data: ApiCollection<any>) => {
                if (currentCount !== this.listLoadCount) {
                    return;
                }
                this.loading = false;
                this.data = data;
                this.meta = data.meta;
                return data;
            });
    }
    delete(contact): ng.IPromise<any> {
        let msg;
        if (contact.allow_deletion) {
            msg = this.gettextCatalog.getString('Are you sure you want to delete {{contact}} from {{accountList}}?', {
                accountList: contact.account_list.name,
                contact: contact.name,
            });
        } else {
            msg = this.gettextCatalog.getString(
                'Are you sure you want to anonymize {{contact}} from {{accountList}}?',
                {
                    accountList: contact.account_list.name,
                    contact: contact.name,
                },
            );
        }
        return this.modal.confirm(msg).then(() => {
            return this.api
                .delete({
                    url: `organizations/contacts/${contact.id}`,
                    type: 'contacts',
                })
                .then(() => {
                    this.data = reject({ id: contact.id }, this.data) as ApiCollection<any>;
                });
        });
    }
    resetAll(): void {
        this.organizationIds = [];
        this.wildcardSearch = '';
        this.load();
    }
    resetOrganizationIds(): void {
        this.organizationIds = [];
        this.load();
    }
}

const Contacts = {
    template: require('./contacts.html'),
    controller: ContactsController,
};

export default angular
    .module('mpdx.preferences.organizations.contacts.component', ['gettext', api, modal, preferencesOrganizations])
    .component('preferencesOrganizationsContacts', Contacts).name;
