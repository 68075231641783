import { concat, contains, find, map, reduce } from 'lodash/fp';
import { StateService } from '@uirouter/core';
import api, { ApiService } from '../../../common/api/api.service';
import appeals, { AppealsService } from '../appeals.service';
import contactFilter, { ContactFilterService } from '../../../contacts/sidebar/filter/filter.service';
import contacts, { ContactsService } from '../../../contacts/contacts.service';
import contactTags, { ContactsTagsService } from '../../../contacts/sidebar/filter/tags/tags.service';
import uiRouter from '@uirouter/angularjs';

class WizardController {
    appeal: any;
    excludes: string[];
    goal: any;
    saving: boolean;
    statuses: string[];
    statusFilter: any;
    tags: string[];
    watcher: () => void;
    constructor(
        private $log: ng.ILogService,
        private $q: ng.IQService,
        $rootScope: ng.IRootScopeService,
        private $state: StateService,
        private appeals: AppealsService,
        private api: ApiService,
        private contactFilter: ContactFilterService,
        private contacts: ContactsService,
        private contactsTags: ContactsTagsService,
    ) {
        this.watcher = $rootScope.$on('accountListUpdated', () => {
            this.contactsTags.load();
            this.init();
        });
    }
    $onInit() {
        this.init();

        this.statusFilter = find({ name: 'status' }, this.contactFilter.data);
    }
    $onDestroy() {
        this.watcher();
    }
    init() {
        this.statuses = ['active'];
        this.tags = [];
        this.excludes = ['doNotAskAppeals'];
        this.appeal = {};
        this.saving = false;
        this.goal = {
            initial: 0,
            letterCost: 0,
            adminPercent: 12,
        };

        this.calculateGoal();
    }
    calculateGoal() {
        const adminPercent = 1 - Number(this.goal.adminPercent) / 100;
        const initialGoal = Number(this.goal.initial);
        const letterCost = Number(this.goal.letterCost);
        this.appeal.amount = Math.round(((initialGoal + letterCost) / adminPercent) * 100) / 100;
    }
    selectAllStatuses() {
        if (this.statuses.length === this.statusFilter.options.length) {
            this.statuses = [];
        } else {
            this.statuses = reduce(
                (result, value) => {
                    return contains(value.id, ['hidden', 'active']) ? result : concat(result, value.id);
                },
                [],
                this.statusFilter.options,
            );
        }
    }
    selectAllTags() {
        if (this.tags.length === this.contactsTags.data.length) {
            this.tags = [];
        } else {
            this.tags = map('name', this.contactsTags.data);
        }
    }
    save(): ng.IPromise<void> {
        this.saving = true;
        return this.appeals
            .create({
                appeal: this.appeal,
                excludes: this.excludes,
                statuses: this.statuses,
                tags: this.tags,
            })
            .then((data: any) => {
                /* istanbul ignore next */
                this.$log.debug('appealAdded', data);
                this.saving = false;
                this.$state.go('tools.appeals.show', {
                    appealId: data.id,
                    tour: 'start',
                });
            })
            .catch((ex) => {
                this.saving = false;
                throw ex;
            });
    }
}

const AppealsWizard = {
    controller: WizardController,
    template: require('./wizard.html'),
};

export default angular
    .module('mpdx.tools.appeals.wizard.component', [uiRouter, appeals, api, contactFilter, contacts, contactTags])
    .component('appealsWizard', AppealsWizard).name;
