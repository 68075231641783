import { find } from 'lodash/fp';
import api, { ApiService } from '../../common/api/api.service';

export class OrganizationsService {
    adminList: any[];
    inviteList: any[];
    current: any;
    list: any[];
    constructor(private $log: ng.ILogService, private $q: ng.IQService, private api: ApiService) {
        this.adminList = [];
        this.inviteList = [];
        this.list = [];
    }
    load(reset = false): ng.IPromise<any> {
        if (!reset && this.list.length > 0) {
            return this.$q.resolve(this.list);
        }
        return this.api
            .get({
                url: 'organizations',
                data: {
                    per_page: 2500,
                    fields: {
                        organization: 'name',
                    },
                },
            })
            .then((data: any[]) => {
                this.list = data;
                /* istanbul ignore next */
                this.$log.debug('organizations', data);
                this.setCurrent();
                return data;
            });
    }
    setCurrent(id = null): any {
        this.current = find({ id: id }, this.list) || this.list[0];
        if (this.current) {
            this.adminList = [];
            this.inviteList = [];
            this.listAdmins();
            this.listInvites();
        }
        return this.current;
    }
    listInvites(): ng.IPromise<void> {
        return this.api
            .get({
                url: `organizations/${this.current.id}/invites`,
                include: 'invited_by_user',
                fields: {
                    contacts: 'first_name,last_name',
                },
                filter: {
                    invite_user_as: 'admin',
                },
            })
            .then((data) => {
                this.inviteList = data;
                /* istanbul ignore next */
                this.$log.debug(`organizations/${this.current.id}/invites`, this.inviteList);
                return data;
            });
    }
    createInvite(email, successMessage, errorMessage) {
        return this.api
            .post({
                url: `organizations/${this.current.id}/invites`,
                data: { recipient_email: email, invite_user_as: 'admin' },
                type: 'organization_invites',
                successMessage: successMessage,
                errorMessage: errorMessage,
                retryable: false,
            })
            .then(() => {
                return this.listInvites();
            });
    }
    destroyInvite(id, successMessage, errorMessage): ng.IPromise<void> {
        return this.api.delete({
            url: `organizations/${this.current.id}/invites/${id}`,
            type: 'organization_invites',
            successMessage: successMessage,
            errorMessage: errorMessage,
        });
    }
    listAdmins(): ng.IPromise<void> {
        return this.api
            .get({
                url: `organizations/${this.current.id}/admins`,
                include: 'user,user.email_addresses',
                fields: {
                    email_addresses: 'email,primary',
                    users: 'email_addresses,first_name,last_name',
                },
                per_page: 100,
            })
            .then((data) => {
                this.adminList = data;
                /* istanbul ignore next */
                this.$log.debug(`organizations/${this.current.id}/admins`, this.adminList);
                return data;
            });
    }
    destroyAdmin(id, successMessage, errorMessage): ng.IPromise<void> {
        return this.api.delete({
            url: `organizations/${this.current.id}/admins/${id}`,
            type: 'admins',
            successMessage: successMessage,
            errorMessage: errorMessage,
        });
    }
}

export default angular
    .module('mpdx.preferences.organizations.service', [api])
    .service('preferencesOrganizations', OrganizationsService).name;
