import * as Bowser from 'bowser';
import { defaultTo, every, map } from 'lodash/fp';
import { StateService } from '@uirouter/core';
import md5 from 'md5';
import Modernizr from 'modernizr';
import uiRouter from '@uirouter/angularjs';

export class UnsupportedService {
    requirements: string[];
    constructor(private $state: StateService, private $window: ng.IWindowService) {
        // ensure Modernizr features are included in .modernizrrc as well
        this.requirements = ['cssgrid', 'flexbox'];
    }
    showUnsupported(): boolean {
        return this.mobile() || this.failingRequirements() || this.staging();
    }
    mobile(): boolean {
        return (
            Bowser.getParser(this.$window.navigator.userAgent).getPlatform().type === 'mobile' &&
            defaultTo(true, this.$window.localStorage.getItem('hide_mobile') !== 'true')
        );
    }
    failingRequirements(): boolean {
        return (
            !every(
                Boolean,
                map((feature) => this.modernizr(feature), this.requirements),
            ) &&
            defaultTo(
                true,
                this.$window.localStorage.getItem(`hide_failing_requirements_${md5(this.requirements)}`) !== 'true',
            )
        );
    }
    staging(): boolean {
        return (
            process.env.NODE_ENV === 'staging' &&
            defaultTo(true, this.$window.localStorage.getItem('hide_staging') !== 'true')
        );
    }
    go(): void {
        if (this.mobile()) {
            this.$window.localStorage.setItem('hide_mobile', 'true');
        } else if (this.failingRequirements()) {
            this.$window.localStorage.setItem(`hide_failing_requirements_${md5(this.requirements)}`, 'true');
        } else if (this.staging()) {
            this.$window.localStorage.setItem('hide_staging', 'true');
        }
        this.$state.go('home');
    }
    modernizr(feature): string {
        return Modernizr[feature]; // not tested as value returns differently in different testing environments
    }
}
export default angular.module('mpdx.unsupported.service', [uiRouter]).service('unsupported', UnsupportedService).name;
