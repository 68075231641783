import { assign, defaultTo, get, indexOf, reject } from 'lodash/fp';
import { StateParams } from '@uirouter/core';
import api, { ApiService } from '../../../common/api/api.service';
import contactFilter, { ContactFilterService } from '../../sidebar/filter/filter.service';
import contacts, { ContactsService } from '../../../contacts/contacts.service';
import contactsFlows, { FlowsService } from '../flows.service';
import joinComma from '../../../common/fp/joinComma';
import tasks, { TasksService } from '../../../tasks/tasks.service';
import uiRouter from '@uirouter/angularjs';

interface IFlowsColumnConfig {
    color: string;
    name: string;
    statuses: string[];
}

interface IFlowsColumnSortableOptions {
    containment: string;
    dragStart: (event) => void;
}

export class ColumnController {
    config: IFlowsColumnConfig;
    data: Record<string, any>[];
    sortableOptions: IFlowsColumnSortableOptions;
    listLoadCount: number;
    loading: boolean;
    watcher0: () => void;
    watcher1: () => void;
    watcher2: () => void;
    watcher3: () => void;
    constructor(
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService,
        private $stateParams: StateParams, // used in view
        private api: ApiService,
        private contactFilter: ContactFilterService,
        private contacts: ContactsService,
        private contactsFlows: FlowsService,
        private tasks: TasksService,
    ) {
        this.data = [];
        this.sortableOptions = {
            containment: '.flow-columns',
            dragStart: (event) => {
                this.contactsFlows.setDraggable(event.source.itemScope.modelValue);
            },
        };
        this.listLoadCount = 0;
    }
    $onInit(): void {
        this.watcher0 = this.$rootScope.$on('contactsTagsChange', () => {
            this.load();
        });
        this.watcher1 = this.$rootScope.$on('contactsFilterChange', () => {
            this.load();
        });
        this.watcher2 = this.$rootScope.$on('accountListUpdated', () => {
            this.load();
        });
        this.watcher3 = this.$rootScope.$on('contactUpdated', (_event, contact) => {
            if (contact.status) {
                if (this.statusInConfig(contact.status)) {
                    this.load();
                } else {
                    this.data = reject({ id: contact.id }, this.data);
                }
            }
        });
        this.load();
    }
    $onDestroy(): void {
        this.watcher0();
        this.watcher1();
        this.watcher2();
        this.watcher3();
    }
    load(): ng.IPromise<any> {
        if ((defaultTo(0, get('statuses.length', this.config)) as number) === 0) {
            return this.$q.resolve();
        }
        this.loading = true;
        this.data = null;
        this.listLoadCount++;
        const currentCount = angular.copy(this.listLoadCount);
        return this.api
            .get({
                url: 'contacts',
                data: {
                    filter: this.filter(),
                    page: 1,
                    per_page: 1000,
                    fields: {
                        contacts: 'name,status,square_avatar,uncompleted_tasks_count,tag_list,starred_at',
                    },
                    sort: 'name',
                },
                overrideGetAsPost: true,
            })
            .then((data: any) => {
                if (currentCount !== this.listLoadCount) {
                    return;
                }
                this.loading = false;
                this.data = data;
            });
    }
    filter(): Record<string, any> {
        return assign(this.contactFilter.buildFilterParams(), {
            status: joinComma(defaultTo([], get('statuses', this.config))),
        });
    }
    setContactStatus(status): ng.IPromise<void> {
        const contact = this.contactsFlows.draggable;
        if (contact && contact.status === status) {
            return this.$q.resolve();
        }
        this.loading = true;
        this.transition(contact, status);
        return this.contacts.save({ id: contact.id, status: status }).then(() => {
            if (get('current.id', this.contacts) === contact.id) {
                this.contacts.current.status = status;
            }
        });
    }
    transition(contact, status): void {
        switch (status) {
            case 'Appointment Scheduled':
                this.tasks.addModal({
                    contactsList: [contact.id],
                    activityType: 'Appointment',
                });
                break;
            case 'Call for Decision':
            case 'Contact for Appointment':
                this.tasks.addModal({
                    contactsList: [contact.id],
                    activityType: 'Call',
                });
                break;
        }
    }
    statusInConfig(status) {
        return indexOf(status, this.config.statuses) >= 0;
    }
}

const Column = {
    controller: ColumnController,
    template: require('./column.html'),
    bindings: {
        config: '<',
    },
};

export default angular
    .module('mpdx.contacts.flows.column.component', [uiRouter, api, contactFilter, contacts, contactsFlows, tasks])
    .component('contactsFlowsColumn', Column).name;
