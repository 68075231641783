import { DesignationAccountsService } from '../../../../common/designationAccounts/designationAccounts.service';
import { DonorAccountsService } from '../../../../common/donorAccounts/donorAccounts.service';
import { invert } from 'lodash/fp';
import appeals, { AppealsService } from '../../../../tools/appeals/appeals.service';
import importDonation, { ImportDonationService } from '../donation.service';
import locale, { LocaleService } from '../../../../common/locale/locale.service';
import serverConstants, { ServerConstantsService } from '../../../../common/serverConstants/serverConstants.service';

class ValuesController {
    donation: any;
    donorMatched: any;
    designationMatched: any;
    appealMatched: any;
    defaultCurrencyMatched: any;
    matched: any;
    emptyArray: any;
    duplicatedCollection: any;
    currencyModel: any;
    dateModel: any;
    paymentModel: any;
    apealModel: any;
    accountNames: any;
    constructor(
        private importDonation: ImportDonationService,
        private serverConstants: ServerConstantsService,
        private donorAccounts: DonorAccountsService,
        private designationAccounts: DesignationAccountsService,
        private appeals: AppealsService,
        private locale: LocaleService,
    ) {
        this.donorMatched = {};
        this.designationMatched = {};
        this.appealMatched = {};
        this.emptyArray = {};
        this.matched = {};
        this.duplicatedCollection = {};
        this.defaultCurrencyMatched = {};
        this.currencyModel = {};
        this.dateModel = {};
        this.paymentModel = {};
        this.apealModel = {};
        this.accountNames = [];
    }
    $onInit(): void {
        this.emptyArrayValidator();
        this.importDonation.makeUniqueFileConstants();
        this.importDonation.accountListAppeals = [];
        this.prepareAccountListAppeals();
        this.accountNames = this.importDonation.data.file_constants.account_name?.filter(
            (value, index, array) => array.indexOf(value) === index,
        );
    }
    prepareAccountListAppeals() {
        this.importDonation.data.appeals.map((item: any) => {
            this.importDonation.accountListAppeals.push(item.name);
        });
    }
    existingIndex(list, index) {
        return list.findIndex((item) => item.key === index);
    }
    save() {
        this.importDonation.save();
    }
    back() {
        this.importDonation.back();
    }
    fileHeader(constant) {
        return this.importDonation.data.file_headers[invert(this.importDonation.data.file_headers_mappings)[constant]];
    }
    emptyArrayValidator() {
        const constants = this.importDonation.data.file_constants;
        Object.keys(constants).forEach((key) => {
            this.emptyArray[key] = constants[key].every((element: string) => element === '');
        });
    }
    onDonorAccountSelected(accountName, accountNumber, donorAccount: any, key: number) {
        this.importDonation.donorAccountCollectionForDisplay[key] = {
            key: key,
            accountNumber: donorAccount.account_number,
            accountName: donorAccount.name,
            displayName: donorAccount.display_name,
        };
        this.importDonation.data.file_constants.account_name?.forEach((item, index) => {
            if (item === accountName) {
                this.importDonation.filterByValue(
                    this.importDonation.data,
                    this.importDonation.file_headers_mappings,
                    'account_name',
                    accountName,
                );
                this.importDonation.filterByValue(
                    this.importDonation.data,
                    this.importDonation.file_headers_mappings,
                    'account_number',
                    accountNumber,
                );
                let existingIndex = this.existingIndex(this.importDonation.donorAccountCollection, index);
                if (existingIndex === -1) {
                    this.importDonation.donorAccountCollection.push({
                        key: index,
                        accountNumber: donorAccount.account_number,
                        accountName: donorAccount.name,
                        displayName: donorAccount.display_name,
                    });
                } else {
                    this.importDonation.donorAccountCollection[existingIndex].accountNumber =
                        donorAccount.account_number;
                    this.importDonation.donorAccountCollection[existingIndex].accountName = donorAccount.name;
                    this.importDonation.donorAccountCollection[existingIndex].displayName = donorAccount.display_name;
                }
                this.importDonation.checkAndUpdateValue('account_name', accountName, donorAccount.name, 'account_name');
                this.importDonation.checkAndUpdateValue(
                    'account_number',
                    accountNumber,
                    donorAccount.account_number,
                    'account_number',
                );
            }
        });
    }
    onDesignationAccountSelected(designationAccountNumber: string, designationAccount: any, key: number) {
        this.importDonation.designationAccountCollectionForDisplay[key] = {
            key: key,
            accountName: designationAccount.name,
            accountNumber: designationAccount.designation_number,
            displayName: designationAccount.display_name,
        };
        this.importDonation.data.file_constants.designation_account_number.forEach((item, index) => {
            if (item === designationAccountNumber) {
                this.importDonation.filterByValue(
                    this.importDonation.data,
                    this.importDonation.file_headers_mappings,
                    'designation_account',
                    designationAccountNumber,
                );
                let existingIndex = this.existingIndex(this.importDonation.designationAccountCollection, index);
                if (existingIndex === -1) {
                    this.importDonation.designationAccountCollection.push({
                        key: index,
                        accountName: designationAccount.name,
                        accountNumber: designationAccount.designation_number,
                        displayName: designationAccount.display_name,
                    });
                } else {
                    this.importDonation.designationAccountCollection[existingIndex].accountName =
                        designationAccount.name;
                    this.importDonation.designationAccountCollection[existingIndex].accountNumber =
                        designationAccount.designation_number;
                    this.importDonation.designationAccountCollection[existingIndex].displayName =
                        designationAccount.display_name;
                }
                this.importDonation.checkAndUpdateValue(
                    'designation_account',
                    designationAccountNumber,
                    designationAccount.designation_number,
                    'designation_account',
                );
            }
        });
    }
    onAppealSelected(appealName: string, appeal: any, key: number) {
        this.importDonation.appealCollectionForDisplay[key] = appeal.name;
        if (!this.importDonation.accountListAppeals.includes(appeal.name)) {
            // If it doesn't exist, push the new string
            this.importDonation.accountListAppeals.push(appeal.name);
        }
        this.importDonation.data.file_constants.appeal.forEach((item, index) => {
            if (item === appealName) {
                this.importDonation.appealCollection[index] = appeal.name;
                this.importDonation.filterByValue(
                    this.importDonation.data,
                    this.importDonation.file_headers_mappings,
                    'appeal',
                    appealName,
                );
                this.importDonation.checkAndUpdateValue('appeal', appealName, appeal.name, 'appeal');
            }
        });
    }
    checkDuplicate(key: any, value: any) {
        let uniqueSet = [];
        if (!(key in uniqueSet)) {
            uniqueSet[key] = value;
        }
    }
    updatePaymentMethod(paymentMethod, value) {
        this.importDonation.data.file_constants.payment_method.forEach((item, index) => {
            if (item === paymentMethod) {
                this.importDonation.paymentMethodCollection[index] = value;
                this.importDonation.filterByValue(
                    this.importDonation.data,
                    this.importDonation.file_headers_mappings,
                    'payment_method',
                    paymentMethod,
                );
                this.importDonation.checkAndUpdateValue('payment_method', paymentMethod, value, 'payment_method');
            }
        });
    }
    updateCurrency(currency, value) {
        this.importDonation.data.file_constants.currency.forEach((item, index) => {
            if (item === currency) {
                this.importDonation.currencyCollection[index] = value;
                this.importDonation.filterByValue(
                    this.importDonation.data,
                    this.importDonation.file_headers_mappings,
                    'currency',
                    currency,
                );
                this.importDonation.checkAndUpdateValue('currency', currency, value, 'currency');
            }
        });
    }
    updateDonationDate(date, value) {
        this.importDonation.data.file_constants.donation_date.forEach((item, index) => {
            if (item === date) {
                this.importDonation.donationDateCollection[index] = value;
                this.importDonation.filterByValue(
                    this.importDonation.data,
                    this.importDonation.file_headers_mappings,
                    'donation_date',
                    date,
                );
                this.importDonation.checkAndUpdateValue('donation_date', date, value, 'donation_date');
            }
        });
    }
}

const Values: ng.IComponentOptions = {
    controller: ValuesController,
    template: require('./values.html'),
};

export default angular
    .module('mpdx.preferences.donation.values.component', [importDonation, serverConstants, locale, appeals])
    .component('importDonationValues', Values).name;
