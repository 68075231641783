import { StateService } from '@uirouter/core';
import uiRouter from '@uirouter/angularjs';

export class ShowController {
    watcher: () => void;
    constructor(private $rootScope: ng.IRootScopeService, private $state: StateService) {}
    $onInit(): void {
        this.watcher = this.$rootScope.$on('accountListUpdated', () => {
            this.$state.go('reports.financialAccounts');
        });
    }
    $onDestroy(): void {
        this.watcher();
    }
}

const Show = {
    controller: ShowController,
    template: require('./show.html'),
};

export default angular
    .module('mpdx.reports.financialAccounts.show.component', [uiRouter])
    .component('financialAccountsShow', Show).name;
