import preferencesOrganizations, { OrganizationsService } from './organizations.service';

class OrganizationsController {
    currentId: string;
    selectedTab: string;
    tabId: string;
    constructor(private preferencesOrganizations: OrganizationsService) {
        this.tabId = 'impersonate_user';
    }
    $onInit() {
        this.currentId = this.preferencesOrganizations.current.id;
    }
    setTab(service) {
        if (service === '' || this.tabId === service) {
            if (!this.selectedTab) {
                this.tabId = '';
            }
        } else if (this.selectedTab) {
            if (this.tabSelectable(service)) {
                this.tabId = service;
            }
        } else {
            this.tabId = service;
        }
    }
    tabSelectable(service) {
        if (this.selectedTab) {
            return this.selectedTab === service;
        }
        return true;
    }
    tabSelected(service) {
        return this.tabId === service;
    }
}

const Organizations = {
    controller: OrganizationsController,
    template: require('./organizations.html'),
};

export default angular
    .module('mpdx.preferences.organizations.component', [preferencesOrganizations])
    .component('preferencesOrganizations', Organizations).name;
