import { ImportDonationService } from '../../donation.service';

class DesignationAccountModalController {
    designationAccountKey: number;
    csvDesignationAccount: any;
    accountNumber: string;
    constructor(
        private importDonation: ImportDonationService,
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        designationAccountKey,
        csvDesignationAccount,
    ) {
        this.designationAccountKey = angular.copy(designationAccountKey);
        this.csvDesignationAccount = angular.copy(csvDesignationAccount);
    }
    save() {
        let { designationAccountCollection } = this.importDonation;
        const designation = this.importDonation.data.organization.name;
        const displayName = `${designation} (${this.accountNumber})`;
        this.importDonation.designationAccountCollectionForDisplay[this.designationAccountKey] = {
            key: this.designationAccountKey,
            accountName: designation,
            accountNumber: this.accountNumber,
            displayName: displayName,
        };
        this.importDonation.filterByValue(
            this.importDonation.data,
            this.importDonation.file_headers_mappings,
            'designation_account',
            this.csvDesignationAccount,
        );
        designationAccountCollection.push({
            key: this.designationAccountKey,
            accountName: designation,
            accountNumber: this.accountNumber,
            displayName,
        });
        this.importDonation.checkAndUpdateValue(
            'designation_account',
            this.csvDesignationAccount,
            this.accountNumber,
            'designation_account',
        );
        return this.$scope.$hide();
    }
}

export default angular
    .module('mpdx.preferences.donation.modal.createDesignationAccountModal', [])
    .controller('createDesignationAccountModalController', DesignationAccountModalController).name;
