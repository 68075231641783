import { invert } from 'lodash/fp';
import importDonation, { ImportDonationService } from '../donation.service';

class DonationIdController {
    updatedValue: any;
    accNumberMatched: any;
    accNameMatched: any;
    designationMatched: any;
    generated: any;
    generateEmpty: any;
    existedIds: any;
    donationDisplayDate: any;
    matches: boolean;
    emptyRemoteIds: {};
    donationKey: {};
    constructor(private importDonation: ImportDonationService) {
        this.updatedValue = {};
        this.accNameMatched = {};
        this.accNumberMatched = {};
        this.designationMatched = {};
        this.donationDisplayDate = {};
        this.generated = {};
        this.existedIds = angular.copy(this.importDonation.data.existing_remote_ids);
        this.matches = false;
        this.emptyRemoteIds = {};
        this.donationKey = {};
        this.generateEmpty = {};
    }
    $onInit() {
        this.emptyRemoteIds = {};
        this.donationKey = {};
        this.convertDateFormat();
        this.compareEmptyRemoteID();
    }
    save() {
        this.importDonation.save();
    }
    back() {
        this.importDonation.back();
    }
    fileHeader(constant) {
        return this.importDonation.data.file_headers[invert(this.importDonation.data.file_headers_mappings)[constant]];
    }
    convertDateFormat() {
        let date = this.importDonation.data.file_constants[this.importDonation.file_headers_mappings.donation_date];
        date.forEach((item, key) => {
            let parsedDate = new Date(item);
            this.importDonation.formattedDataCollection[key] = `${
                parsedDate.getMonth() + 1
            }/${parsedDate.getDate()}/${parsedDate.getFullYear()}`;
        });
    }
    checkDonorID(donationId: any) {
        const donationFromPE = this.importDonation.data.donations;
        return donationFromPE.some((item) => donationId === item.remote_id);
    }
    timeStampConvert(stringDate: string, donationKey) {
        let unixtimestamp = new Date(`${stringDate} 00:00:00`).getTime() / 1000;
        this.donationDisplayDate[donationKey] = unixtimestamp;
    }
    generateID(key, value) {
        let timestamp =
            new Date(
                `${
                    this.importDonation.data.file_constants[this.importDonation.file_headers_mappings.donation_date][
                        key
                    ]
                } 00:00:00`,
            ).getTime() / 1000;

        this.existedIds.forEach((item) => {
            if (item === value) {
                this.importDonation.generatedIdCollection.push({
                    key: key,
                    id: `gen-${value}-${
                        this.importDonation.data.file_constants[
                            this.importDonation.file_headers_mappings.account_number
                        ][key]
                    }-${timestamp}-${
                        this.importDonation.data.file_constants[this.importDonation.file_headers_mappings.amount][key] *
                        100
                    }`,
                    orginalID: item,
                });
                this.importDonation.updatedValue.push({
                    key: key,
                    header: 'remote_id',
                    value: `gen-${value}-${
                        this.importDonation.data.file_constants[
                            this.importDonation.file_headers_mappings.account_number
                        ][key]
                    }-${timestamp}-${
                        this.importDonation.data.file_constants[this.importDonation.file_headers_mappings.amount][key] *
                        100
                    }`,
                });
            }
        });
    }
    generateRandomString(): string {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '';

        for (let i = 0; i < 10; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters.charAt(randomIndex);
        }

        return randomString;
    }
    generateEmptyRemoteIds(key, orginalId) {
        let randomRemoteId = this.generateRandomString();
        let timestamp =
            new Date(
                `${
                    this.importDonation.data.file_constants[this.importDonation.file_headers_mappings.donation_date][
                        key
                    ]
                } 00:00:00`,
            ).getTime() / 1000;

        this.importDonation.generatedIdCollection.push({
            key: key,
            id: `gen-${randomRemoteId}-${
                this.importDonation.data.file_constants[this.importDonation.file_headers_mappings.account_number][key]
            }-${timestamp}-${
                this.importDonation.data.file_constants[this.importDonation.file_headers_mappings.amount][key] * 100
            }`,
            orginalID: orginalId,
        });
        this.importDonation.updatedValue.push({
            key: key,
            header: 'remote_id',
            value: `gen-${randomRemoteId}-${
                this.importDonation.data.file_constants[this.importDonation.file_headers_mappings.account_number][key]
            }-${timestamp}-${
                this.importDonation.data.file_constants[this.importDonation.file_headers_mappings.amount][key] * 100
            }`,
        });
        this.emptyRemoteIds[key] = false;
    }
    compareEmptyRemoteID() {
        const constants = this.importDonation.data.file_constants;
        constants[this.importDonation.file_headers_mappings.remote_id].forEach((item, index) => {
            let csvDisplayDate =
                new Date(
                    `${
                        this.importDonation.data.file_constants[
                            this.importDonation.file_headers_mappings.donation_date
                        ][index]
                    } 00:00:00`,
                ).getTime() / 1000;
            this.importDonation.data.donations.forEach((donation, key) => {
                let donationDate = new Date(`${donation.donation_date} 00:00:00`).getTime() / 1000;
                if (
                    item === '' &&
                    constants[this.importDonation.file_headers_mappings.account_number][index] ===
                        donation.donor_account.account_number &&
                    csvDisplayDate === donationDate &&
                    constants[this.importDonation.file_headers_mappings.amount][index] * 100 === donation.amount * 100
                ) {
                    this.emptyRemoteIds[index] = true;
                    this.donationKey[index] = key;
                }
            });
        });
    }
    findDonorAccount(id: string) {
        return this.importDonation.data.donor_accounts.find((d) => d.id === id);
    }
    findDesignationAccount(id: string) {
        return this.importDonation.data.designation_accounts.find((d) => d.id === id);
    }
    getDonorName(donationDonorAccount, donorAccount) {
        let name = '';
        if (donationDonorAccount) {
            name = donationDonorAccount.name;
        } else {
            name = donorAccount.name;
        }

        return name === 'Donor' ? 'Not Define' : name;
    }
}

const DonationIdValidation: ng.IComponentOptions = {
    controller: DonationIdController,
    template: require('./donationId.html'),
};

export default angular
    .module('mpdx.preferences.donation.donationId.component', [importDonation])
    .component('donationIdValidation', DonationIdValidation).name;
