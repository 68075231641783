import { assign, concat, find, map, reduce, toInteger } from 'lodash/fp';
import api, { ApiService } from '../api/api.service';

export class DesignationAccountsService {
    balance: number;
    data: any;
    dataByOrganization: any;
    list: any;
    organizations: any;
    selected: any;
    constructor(
        private $log: ng.ILogService,
        private $rootScope: ng.IRootScopeService,
        private $q: ng.IQService,
        private api: ApiService,
    ) {
        this.data = [];
        this.balance = 0;
        this.list = [];
        this.organizations = [];
        this.selected = [];
    }
    load(reset = false): ng.IPromise<any> {
        if (!reset && this.data.length > 0) {
            return this.$q.resolve(this.data);
        }

        this.data = [];
        this.balance = 0;
        this.list = [];
        this.organizations = [];
        this.resetSelected();

        return this.api
            .get(`account_lists/${this.api.account_list_id}/designation_accounts`, {
                per_page: 10000,
                include: 'organization',
            })
            .then((data) => {
                this.$log.debug(`account_lists/${this.api.account_list_id}/designation_accounts`, data);
                this.data = data;
                this.dataByOrganization = reduce(
                    (result, designationAccount: { organization: { id: string } }) => {
                        let organization = find({ id: designationAccount.organization.id }, result);
                        if (!organization) {
                            organization = angular.copy(designationAccount.organization);
                            organization.designationAccounts = [];
                            result.push(organization);
                        }
                        organization.designationAccounts.push(designationAccount);
                        return result;
                    },
                    [],
                    this.data,
                );
                this.organizations = reduce(
                    (result, value) => {
                        if (!find({ id: value.organization.id }, result)) {
                            return concat(result, value.organization);
                        }
                        return result;
                    },
                    [],
                    data,
                );
                this.$log.debug('designation organizations', this.organizations);
                this.updateBalance();
                return this.data;
            });
    }
    get(accountListId: string): ng.IPromise<any> {
        return this.api.get(`account_lists/${accountListId}/designation_accounts`, {
            include: 'organization',
        });
    }
    save(designationAccount: any): ng.IPromise<any> {
        return this.api
            .put({
                url: `account_lists/${this.api.account_list_id}/designation_accounts/${designationAccount.id}`,
                data: designationAccount,
                type: 'designation_accounts',
            })
            .then((data) => {
                this.data = map((designationAccount) => {
                    return data.id === designationAccount.id ? assign(designationAccount, data) : designationAccount;
                }, this.data);
                this.updateBalance();
                return data;
            });
    }
    search(keywords: string, org_id = ''): ng.IPromise<any> {
        return this.api.get(`account_lists/${this.api.account_list_id}/designation_accounts`, {
            filter: {
                wildcard_search: keywords,
                organization_id: org_id,
            },
            fields: {
                designation_accounts: 'display_name,designation_number,name',
            },
            per_page: 6,
        });
    }
    updateBalance(): void {
        this.balance = reduce(
            (result, designationAccount) =>
                result + toInteger(designationAccount.active ? designationAccount.converted_balance : 0),
            0,
            this.data,
        );
    }
    resetSelected(): void {
        // Without this condition, the event is fired every time the load function is called.
        // Some event handlers call the load function resulting in infinite recursion.
        if (this.selected.length !== 0) {
            this.selected = [];
            this.$rootScope.$emit('designationAccountSelectorChanged', this.selected);
        }
    }
}

export default angular
    .module('mpdx.common.designationAccounts.service', [api])
    .service('designationAccounts', DesignationAccountsService).name;
