// do not disable angular debug in production. breaks daterangepicker
import Routes from './routes';

/* @ngInject*/
export default function appConfig(
    $compileProvider,
    $locationProvider,
    $logProvider,
    $stateProvider,
    $httpProvider,
    $qProvider,
    $urlRouterProvider,
    blockUIConfig,
    jwtOptionsProvider,
    RollbarProvider,
    timeAgoSettings,
    gettext,
) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|file|tel|mailto|javascript):/);
    jwtOptionsProvider.config({
        // redirects handled in app.run router to avoid un-authorized api calls
        tokenGetter: /* @ngInject*/ ($window) => {
            return $window.localStorage.getItem('token');
        },
        whiteListedDomains: ['api.stage.mpdx.org', 'api.mpdx.org', 'localhost'],
    });
    $httpProvider.interceptors.push('jwtInterceptor');
    $qProvider.errorOnUnhandledRejections(false); // hide ui-router 'Possibly unhandled rejection' and no-catch conditions
    $urlRouterProvider.otherwise('/unavailable');
    $locationProvider
        .html5Mode({
            enabled: true,
            rewriteLinks: false,
        })
        .hashPrefix('!');
    Routes.config($stateProvider, gettext);
    blockUIConfig.autoBlock = false;
    blockUIConfig.template = require('./blockUI/blockUI.html');
    $logProvider.debugEnabled(process.env.NODE_ENV === 'development');
    if (process.env.ROLLBAR_ACCESS_TOKEN) {
        RollbarProvider.init({
            accessToken: process.env.ROLLBAR_ACCESS_TOKEN,
            captureUncaught: true,
            payload: {
                environment: process.env.NODE_ENV,
                client: {
                    javascript: {
                        source_map_enabled: true,
                        code_version: process.env.AWS_COMMIT_ID,
                        guess_uncaught_frames: true,
                    },
                },
            },
        });
    } else {
        RollbarProvider.deinit();
    }
    timeAgoSettings.strings.en_US.suffixAgo = '';
}
