import * as moment from 'moment';
import { reduce } from 'lodash/fp';
import { StateParams } from '@uirouter/core';
import api, { ApiService } from '../../../common/api/api.service';
import financialAccounts, {
    FinancialAccountsService,
} from '../../../common/financialAccounts/financialAccounts.service';
import locale, { LocaleService } from '../../../common/locale/locale.service';
import session, { SessionService } from '../../../common/session/session.service';
import uiRouter from '@uirouter/angularjs';

export class EntriesController {
    data: any[];
    meta: any;
    sort: string;
    dateRangeLocale: any;
    dateRange: string;
    financialAccount: any;
    loading: boolean;
    wildcardSearch: string;
    collapseReportFilters: boolean;
    startDate: string;
    endDate: string;
    categoryId: string;
    categories: any[];
    moment: any;
    constructor(
        private $q: ng.IQService,
        private $stateParams: StateParams,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private api: ApiService,
        private financialAccounts: FinancialAccountsService,
        private locale: LocaleService, // used in view
        private session: SessionService, // used in view
    ) {
        this.data = [];
        this.startDate = this.$stateParams.startDate || moment().subtract(29, 'd').locale('en').format('YYYY-MM-DD');
        this.endDate = this.$stateParams.endDate || moment().locale('en').format('YYYY-MM-DD');
        this.dateRange = `${this.startDate}..${this.endDate}`;
        this.sort = '-entry_date';
        this.dateRangeLocale = {
            applyLabel: this.gettextCatalog.getString('Filter'),
            cancelLabel: this.gettextCatalog.getString('Clear'),
        };
        this.collapseReportFilters = false;
        this.categoryId = this.$stateParams.categoryId;
        this.moment = moment;
    }
    $onInit(): void {
        this.load();
        this.loadCategories();
    }
    load(reset = false): ng.IPromise<any> {
        if (!reset && this.data.length > 0) {
            return this.$q.resolve(this.data);
        }
        this.loading = true;
        return this.api
            .get(`account_lists/${this.api.account_list_id}/entries`, {
                fields: {
                    financial_account_entry_debits: 'amount,code,currency,description,entry_date,category,type',
                    financial_account_entry_credits: 'amount,code,currency,description,entry_date,category,type',
                    financial_account_entry_categories: 'name,code',
                },
                include: 'category',
                sort: this.sort,
                per_page: 10000,
                filter: this.filters(),
            })
            .then((data) => {
                this.meta = data.meta;
                this.data = data;
                this.loading = false;
            });
    }
    loadCategories(): ng.IPromise<any> {
        return this.financialAccounts.loadCategories(this.$stateParams.financialAccountId).then((data) => {
            this.categories = data;
        });
    }
    filters(): any {
        return {
            financialAccountId: this.$stateParams.financialAccountId,
            categoryId: this.categoryId,
            entryDate: this.dateRange,
            wildcardSearch: this.wildcardSearch,
        };
    }
    toggleSort(): void {
        this.sort = this.sort === '-entry_date' ? 'entry_date' : '-entry_date';
        this.load(true);
    }
    toCSV(): any[] {
        const columnHeaders = [
            this.gettextCatalog.getString('Date'),
            this.gettextCatalog.getString('Payee'),
            this.gettextCatalog.getString('Memo'),
            this.gettextCatalog.getString('Outflow'),
            this.gettextCatalog.getString('Inflow'),
        ];

        return reduce(
            (result, entry) => [
                ...result,
                [
                    moment(entry.entry_date).locale('en').format('MM/DD/YYYY'),
                    entry.description,
                    entry.category.name,
                    entry.type === 'FinancialAccount::Entry::Debit' ? entry.amount : '',
                    entry.type === 'FinancialAccount::Entry::Credit' ? -entry.amount : '',
                ],
            ],
            [columnHeaders],
            this.data,
        );
    }
}

const Entries = {
    controller: EntriesController,
    template: require('./entries.html'),
};

export default angular
    .module('mpdx.reports.financialAccounts.entries.component', [
        'gettext',
        uiRouter,
        api,
        financialAccounts,
        locale,
        session,
    ])
    .component('financialAccountsEntries', Entries).name;
