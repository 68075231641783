import { StateParams } from '@uirouter/core';
import accounts, { AccountsService } from '../../common/accounts/accounts.service';
import uiRouter from '@uirouter/angularjs';

export class RewriteHandoffService {
    newOrigin = process.env.REWRITE_DOMAIN;
    useRewrite = process.env.USE_REWRITE === 'true';
    constructor(private $window: ng.IWindowService, private $q: ng.IQService, private accounts: AccountsService) {}
    handleHandOff(path: string, $stateParams?: StateParams) {
        const deferred = this.$q.defer();
        try {
            if (!this.isEarlyAdopter()) {
                deferred.resolve();
            } else {
                const url = new URL(`${this.newOrigin}/api/mpdx-web-handoff`);
                if ($stateParams) {
                    for (const [key, value] of Object.entries($stateParams)) {
                        if (typeof value === 'string') {
                            url.searchParams.append(key, value);
                        }
                    }
                }
                url.searchParams.append('accountListId', this.accounts.current.id);
                url.searchParams.append('path', path);
                this.$window.location.href = url.toString();
                deferred.reject();
            }
        } catch {
            deferred.resolve();
        }
        return deferred.promise;
    }
    isEarlyAdopter() {
        return this.useRewrite && this.accounts?.current?.tester === true;
    }
    hasLoggedintoRewriteApp() {
        if (!this.isEarlyAdopter()) return false;
        const redirectCookie = document.cookie?.split('mpdx-handoff.logged-in=')[1]?.split(';')[0];
        return redirectCookie === 'true';
    }
}

export default angular
    .module('mpdx.common.rewritehandoff.service', [uiRouter, accounts])
    .service('rewritehandoff', RewriteHandoffService).name;
