import 'angular-block-ui';
import 'angular-gettext';
import * as Upload from 'ng-file-upload';
import {
    concat,
    defaultTo,
    difference,
    findIndex,
    findKey,
    includes,
    invert,
    isNil,
    keys,
    merge,
    omitBy,
    reduce,
    values,
} from 'lodash/fp';
import { ModalService } from '../../../common/modal/modal.service';
import { RewriteHandoffService } from '../../../common/rewritehandoff/rewritehandoff.service';
import { StateService } from '@uirouter/core';
import alerts, { AlertsService } from '../../../common/alerts/alerts.service';
import api, { ApiService } from '../../../common/api/api.service';
import createPatch from '../../../common/fp/createPatch';
import help, { HelpService } from '../../../common/help/help.service';
import joinComma from '../../../common/fp/joinComma';
import reduceObject from '../../../common/fp/reduceObject';
import serverConstants, { ServerConstantsService } from '../../../common/serverConstants/serverConstants.service';
import uiRouter from '@uirouter/angularjs';

interface IAccount {
    key: number;
    accountNumber: string;
    accountName?: string;
    displayName: string;
}

interface IUpdatedValue {
    key: number;
    header: any;
    value: string;
}

interface IDonationID {
    key: number;
    id: any;
    orginalID: any;
}

export class ImportDonationService {
    data: any;
    dataInitialState: any;
    blockUI: IBlockUIService;
    values_to_constants_mappings: any;
    updatedValueValidated: any;
    updatedValue: IUpdatedValue[];
    donorAccountCollection: IAccount[];
    donorAccountCollectionForDisplay: any;
    designationAccountCollection: IAccount[];
    designationAccountCollectionForDisplay: any;
    generatedIdCollection: IDonationID[];
    formattedDataCollection: any;
    paymentMethodCollection: any;
    currencyCollection: any;
    appealCollection: any;
    appealCollectionForDisplay: any;
    donationDateCollection: any;
    file_headers_mappings: any;
    uniqueFileConstants: {};
    donorChecked: any;
    accountListAppeals: string[];
    constructor(
        private $log: ng.ILogService,
        private $q: ng.IQService,
        private $state: StateService,
        blockUI: IBlockUIService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private Upload: ng.angularFileUpload.IUploadService,
        private alerts: AlertsService,
        private api: ApiService,
        private help: HelpService,
        private serverConstants: ServerConstantsService,
        private rewritehandoff: RewriteHandoffService,
        private modal: ModalService,
    ) {
        this.blockUI = blockUI.instances.get('preferences.integrations.donation');
        this.donorAccountCollection = [];
        this.donorAccountCollectionForDisplay = {};
        this.designationAccountCollection = [];
        this.designationAccountCollectionForDisplay = {};
        this.updatedValue = [];
        this.file_headers_mappings = {};
        this.generatedIdCollection = [];
        this.paymentMethodCollection = {};
        this.uniqueFileConstants = {};
        this.currencyCollection = {};
        this.formattedDataCollection = {};
        this.donorChecked = {};
        this.donationDateCollection = {};
        this.appealCollection = {};
        this.accountListAppeals = [];
        this.appealCollectionForDisplay = [];
    }
    $onInit(): void {
        this.reset();
    }
    reset(): void {
        this.data = null;
        this.dataInitialState = null;
        this.values_to_constants_mappings = {};
    }
    private process(data: any): void {
        this.data = data;
        this.dataInitialState = angular.copy(this.data);

        this.file_headers_mappings = this.data.file_headers_mappings;
        this.data.file_headers_mappings = invert(this.data.file_headers_mappings);

        this.values_to_constants_mappings = this.constantsMappingsToValueMappings(
            this.data.file_constants_mappings,
            this.data.file_constants,
            this.data.file_headers_mappings,
        );
        if (keys(this.data.file_headers_mappings).length === 0) {
            const fileHeadersMappingsKeys = keys(this.data.file_headers);
            this.data.file_headers_mappings = reduceObject(
                (result, value, key) => {
                    if (includes(key, fileHeadersMappingsKeys)) {
                        result[key] = key;
                    }
                    return result;
                },
                {},
                this.serverConstants.data.donation_import.supported_headers,
            );
        }

        this.data.tag_list = defaultTo([], this.data.tag_list);

        /* istanbul ignore next */
        this.$log.debug('import', this.data);
    }
    get(importId: string): ng.IPromise<void> {
        if (this.data && this.data.id === importId) {
            return this.$q.resolve(this.data);
        }

        this.values_to_constants_mappings = {};

        this.blockUI.start();
        return this.api
            .get(`account_lists/${this.api.account_list_id}/imports/donation/${importId}`, {
                include: 'donor_accounts,designation_accounts,organization,appeals,donations',
            })
            .then((data) => {
                this.blockUI.reset();
                this.process(data);
            });
    }
    constantsMappingsToValueMappings(constantsMappings: any, fileConstants: any, fileHeadersMappings: any): any {
        return reduceObject(
            (result, array, key) => {
                result[key] = this.reduceConstants(array);

                result[key] =
                    fileConstants && fileHeadersMappings
                        ? this.mergeFileConstants(result, key, fileConstants, fileHeadersMappings)
                        : result[key];

                return result;
            },
            {},
            constantsMappings,
        );
    }
    private reduceConstants(array: any[]): any {
        return reduce(
            (result, constant) => {
                const group = reduce(
                    (group, value) => {
                        if (value) {
                            group[value] = constant.id === '' ? null : constant.id;
                        }
                        return group;
                    },
                    {},
                    constant.values,
                );
                return merge(result, group);
            },
            {},
            array,
        );
    }
    private mergeFileConstants(result: any, key: string, fileConstants: any, fileHeadersMappings: any): any {
        const values = keys(result[key]);
        const allValues = this.getConstantValues(key, fileConstants, fileHeadersMappings);
        const unmappedValues = reduce(
            (object: Record<string, string>, csvValue: string) => {
                object[csvValue] = '';
                return object;
            },
            {},
            difference(allValues, values),
        );
        return merge(unmappedValues, result[key]);
    }
    private valueMappingsToConstantsMappings(valueMappings: any, fileConstants: any, fileHeadersMappings: any): any {
        valueMappings = this.buildValueMappings(valueMappings, fileHeadersMappings);

        return reduceObject(
            (result, object, key) => {
                result[key] = this.buildConstantValues(object);

                result[key] =
                    fileConstants && fileHeadersMappings
                        ? this.buildFileConstantValues(result, key, fileConstants, fileHeadersMappings)
                        : result[key];

                return result;
            },
            {},
            valueMappings,
        );
    }
    private buildFileConstantValues(result: any, key: string, fileConstants: any, fileHeadersMappings: any): any {
        const values = reduce((result, object) => concat(result, object.values), [], result[key]);
        const allValues = this.getConstantValues(key, fileConstants, fileHeadersMappings);
        return reduce(
            (object, csvValue) => {
                let constantIndex = findIndex({ id: '' }, object);
                constantIndex = constantIndex > -1 ? constantIndex : object.length;
                object[constantIndex] = defaultTo({ id: '', values: [] }, object[constantIndex]);
                object[constantIndex].values = concat(object[constantIndex].values, csvValue);
                return object;
            },
            result[key],
            difference(allValues, values),
        );
    }
    private buildConstantValues(object: any): any[] {
        return reduceObject(
            (array, constant, value) => {
                constant = constant === null || constant === 'null' ? '' : constant;
                let constantIndex = findIndex({ id: constant }, array);
                constantIndex = constantIndex > -1 ? constantIndex : array.length;
                array[constantIndex] = defaultTo({ id: constant, values: [] }, array[constantIndex]);
                array[constantIndex].values = concat(array[constantIndex].values, value);
                return array;
            },
            [],
            object,
        );
    }
    private buildValueMappings(valueMappings: any, fileHeadersMappings: any): any {
        valueMappings = defaultTo({}, valueMappings);

        const mappedHeaders = values(fileHeadersMappings);
        const constants = keys(this.serverConstants.data.donation_import.constants);
        return reduce(
            (object, mappedHeader) => {
                if (includes(mappedHeader, constants)) {
                    object[mappedHeader] = defaultTo({}, object[mappedHeader]);
                }
                return object;
            },
            valueMappings,
            mappedHeaders,
        );
    }
    private getConstantValues(constant: string, fileConstants: any[], fileHeadersMappings: any): any[] {
        const key = findKey((item) => item === constant, fileHeadersMappings);
        return defaultTo([], fileConstants[key]);
    }
    save(): ng.IPromise<any> {
        if (this.data.tag_list) {
            this.data.tag_list = joinComma(this.data.tag_list);
        }

        this.data.file_headers_mappings = omitBy(isNil, this.data.file_headers_mappings);

        this.data.updated_values = this.updatedValue;

        this.data.file_constants_mappings = this.valueMappingsToConstantsMappings(
            this.values_to_constants_mappings,
            this.data.file_constants,
            this.data.file_headers_mappings,
        );

        this.data.file_headers_mappings = invert(this.data.file_headers_mappings);
        this.file_headers_mappings = this.data.file_headers_mappings;

        const patch = createPatch(this.dataInitialState, this.data);

        this.blockUI.start();

        const errorMessage = this.gettextCatalog.getString(
            'Unable to save your CSV import settings - See help docs or send us a message with your CSV attached',
        );

        return this.api
            .put({
                url: `account_lists/${this.api.account_list_id}/imports/donation/${this.data.id}?include=donor_accounts,designation_accounts,organization,appeals,donations`,
                data: patch,
                type: 'imports',
                errorMessage: errorMessage,
            })
            .then(
                (data: any) => {
                    this.blockUI.reset();
                    this.process(data);
                    this.next(data.id);
                    return data;
                },
                (data) => {
                    this.blockUI.reset();
                    this.data.file_headers_mappings = invert(this.data.file_headers_mappings);
                    /* istanbul ignore next */
                    this.$log.error(data);
                    throw data;
                },
            );
    }
    next(importId: string): void {
        const stateSwitch = (state) =>
            ({
                'preferences.donation.headers': 'preferences.donation.values',
                'preferences.donation.values': this.data.donations
                    ? 'preferences.donation.donationIdValidation'
                    : 'preferences.donation.preview',
                'preferences.donation.donationIdValidation': 'preferences.donation.preview',
            }[state]);
        const nextState = stateSwitch(this.$state.$current.name);
        if (nextState) {
            this.$state.go(nextState, { importId: importId });
        } else {
            this.reset();
            this.$state.go('tools');
        }
    }
    back(): void {
        const stateSwitch = (state) =>
            ({
                'preferences.donation.preview': this.data.donations
                    ? 'preferences.donation.donationIdValidation'
                    : 'preferences.donation.values',
                'preferences.donation.donationIdValidation': 'preferences.donation.values',
                'preferences.donation.values': 'preferences.donation.headers',
                'preferences.donation.headers': 'preferences.integrations',
            }[state]);
        const nextState = stateSwitch(this.$state.$current.name);
        if (nextState === 'preferences.integrations') {
            this.reset();
            this.$state.go(nextState);
        } else if (nextState) {
            this.$state.go(nextState, { importId: this.data.id });
        } else {
            this.reset();
            this.$state.go('preferences.integrations');
        }
    }
    makeArrayUnique(array: string[]): string[] {
        return Array.from(new Set(array));
    }
    makeUniqueFileConstants() {
        for (const key in this.data.file_constants) {
            if (
                Object.prototype.hasOwnProperty.call(this.data.file_constants, key) &&
                Array.isArray(this.data.file_constants[key])
            ) {
                this.uniqueFileConstants[key] = this.makeArrayUnique(this.data.file_constants[key]);
            }
        }
    }
    checkAndUpdateValue = (headerMappingKey: string, value: string, updatedValue: string, header: string) => {
        const array = this.data.file_constants[this.file_headers_mappings[headerMappingKey]];
        if (Array.isArray(array)) {
            array.forEach((item, index) => {
                if (item === value) {
                    this.updatedValue.push({
                        key: index,
                        header,
                        value: updatedValue,
                    });
                }
            });
        }
    };
    filterByValue = (data, mappings, header, value) => {
        const headerIndex = mappings[header];
        data.file_constants[headerIndex].forEach((item, index) => {
            if (item === value) {
                this.updatedValue = this.updatedValue.filter(
                    (updatedValue) => !(updatedValue.key === index && updatedValue.header === header),
                );
            }
        });
    };
    openDonorAccountCreationModal(accountName: any, accountNumber: any, key: any): ng.IPromise<any> {
        if (this.rewritehandoff.isEarlyAdopter()) {
            return this.rewritehandoff.handleHandOff('/?modal=createDonorAccount');
        } else {
            return this.modal.open({
                template: require('./modal/donorAccount/createDonorAccountModal.html'),
                controller: 'createDonorAccountModalController',
                locals: {
                    donorAccountKey: angular.copy(key),
                    csvAccountName: angular.copy(accountName),
                    csvAccountNumber: angular.copy(accountNumber),
                },
            });
        }
    }
    openDesignationAccountCreationModal(csvDesignationAccount: any, key: any): ng.IPromise<any> {
        if (this.rewritehandoff.isEarlyAdopter()) {
            return this.rewritehandoff.handleHandOff('/?modal=createDesignationAccount');
        } else {
            return this.modal.open({
                template: require('./modal/designationAccount/designationAccount.html'),
                controller: 'createDesignationAccountModalController',
                locals: {
                    designationAccountKey: angular.copy(key),
                    csvDesignationAccount: angular.copy(csvDesignationAccount),
                },
            });
        }
    }
    copyDonorAccountFromCSV(csvAccountName: string, csvAccountNumber: string, key: number) {
        const displayName = csvAccountName ? `${csvAccountName} (${csvAccountNumber})` : csvAccountNumber;
        this.donorAccountCollectionForDisplay[key] = {
            key: key,
            accountName: csvAccountName,
            accountNumber: csvAccountName,
            displayName,
        };
        this.data.file_constants.account_name?.forEach((item, index) => {
            if (item === csvAccountName) {
                this.filterByValue(this.data, this.file_headers_mappings, 'account_name', csvAccountName);
                this.filterByValue(this.data, this.file_headers_mappings, 'account_number', csvAccountNumber);
                let existingIndex = this.existingIndex(this.donorAccountCollection, index);
                if (existingIndex === -1) {
                    this.donorAccountCollection.push({
                        key: index,
                        accountName: csvAccountName,
                        accountNumber: csvAccountName,
                        displayName,
                    });
                } else {
                    this.donorAccountCollection[existingIndex].accountName = csvAccountName;
                    this.donorAccountCollection[existingIndex].accountNumber = csvAccountNumber;
                    this.donorAccountCollection[existingIndex].displayName = displayName;
                }
                this.checkAndUpdateValue('account_name', csvAccountName, csvAccountName, 'account_name');
                this.checkAndUpdateValue('account_number', csvAccountNumber, csvAccountNumber, 'account_number');
            }
        });
    }
    copyDesignationAccountFromCSV(csvDesignation: any, key: number) {
        const organization = this.data.organization.name;
        const displayName = organization + ` (${csvDesignation})`;
        this.designationAccountCollectionForDisplay[key] = {
            key: key,
            accountName: organization,
            accountNumber: csvDesignation,
            displayName: displayName,
        };
        this.data.file_constants.designation_account_number.forEach((item, index) => {
            if (item === csvDesignation) {
                this.filterByValue(this.data, this.file_headers_mappings, 'designation_account', csvDesignation);
                let existingIndex = this.existingIndex(this.designationAccountCollection, index);
                if (existingIndex === -1) {
                    this.designationAccountCollection.push({
                        key: index,
                        accountName: organization,
                        accountNumber: csvDesignation,
                        displayName: displayName,
                    });
                } else {
                    this.designationAccountCollection[existingIndex].accountName = organization;
                    this.designationAccountCollection[existingIndex].accountNumber = csvDesignation;
                    this.designationAccountCollection[existingIndex].displayName = displayName;
                }
                this.checkAndUpdateValue('designation_account', csvDesignation, csvDesignation, 'designation_account');
            }
        });
    }
    existingIndex(list, index) {
        return list.findIndex((item) => item.key === index);
    }
}

export default angular
    .module('mpdx.preferences.donation.service', [
        'blockUI',
        'gettext',
        Upload,
        uiRouter,
        alerts,
        api,
        help,
        serverConstants,
    ])
    .service('importDonation', ImportDonationService).name;
