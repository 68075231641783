import { ImportDonationService } from '../../donation.service';

class CreateDonorAccountModalController {
    donorAccountKey: number;
    accountNumber: string;
    accountName: string;
    csvAccountName: string;
    csvAccountNumber: string;
    constructor(
        private importDonation: ImportDonationService,
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        donorAccountKey,
        csvAccountName,
        csvAccountNumber,
    ) {
        this.donorAccountKey = angular.copy(donorAccountKey);
        this.csvAccountNumber = angular.copy(csvAccountNumber);
        this.csvAccountName = angular.copy(csvAccountName);
        this.accountNumber = this.importDonation.data.file_constants[
            this.importDonation.file_headers_mappings.account_name
        ][donorAccountKey]
            ? ''
            : this.importDonation.data.file_constants[this.importDonation.file_headers_mappings.account_number][
                  donorAccountKey
              ];
    }
    save() {
        let { donorAccountCollection } = this.importDonation;
        const displayName = this.accountName ? `${this.accountName} (${this.accountNumber})` : this.accountNumber;

        this.importDonation.donorAccountCollectionForDisplay[this.donorAccountKey] = {
            key: this.donorAccountKey,
            accountName: this.accountName,
            accountNumber: this.accountNumber,
            displayName: displayName,
        };

        this.importDonation.filterByValue(
            this.importDonation.data,
            this.importDonation.file_headers_mappings,
            'account_name',
            this.csvAccountName,
        );
        this.importDonation.filterByValue(
            this.importDonation.data,
            this.importDonation.file_headers_mappings,
            'account_number',
            this.csvAccountNumber,
        );

        donorAccountCollection.push({
            key: this.donorAccountKey,
            accountName: this.accountName,
            accountNumber: this.accountNumber,
            displayName,
        });
        this.importDonation.checkAndUpdateValue('account_name', this.csvAccountName, this.accountName, 'account_name');
        this.importDonation.checkAndUpdateValue(
            'account_number',
            this.csvAccountNumber,
            this.accountNumber,
            'account_number',
        );
        return this.$scope.$hide();
    }
}
export default angular
    .module('mpdx.preferences.donation.modal.createDonorAccountModal', [])
    .controller('createDonorAccountModalController', CreateDonorAccountModalController).name;
