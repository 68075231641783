import 'angular-hotkeys'; // eslint-disable-line import/no-duplicates
import { assign, concat, defaultTo, includes, map, pullAllBy, reduce, reject, sortBy } from 'lodash/fp'; // eslint-disable-line import/no-duplicates
import { HotkeysProvider } from 'angular-hotkeys'; // eslint-disable-line import/no-duplicates
import { StateParams, StateService } from '@uirouter/core';
import api, { ApiService } from '../../common/api/api.service';
import contactFilter, { ContactFilterService } from '../sidebar/filter/filter.service';
import contacts, { ContactsService } from '../contacts.service';
import contactsTags, { ContactsTagsService } from '../sidebar/filter/tags/tags.service';
import pagination from '../../common/pagination/pagination';
import session, { SessionService } from '../../common/session/session.service';
import users, { UsersService } from '../../common/users/users.service';

class ListController {
    allSelected: boolean;
    data: any;
    listLoadCount: number;
    loading: boolean;
    meta: any;
    models: any;
    page: number;
    pageSize: number;
    pagination: pagination;
    totalContactCount: number;
    watcher: any;
    watcher2: any;
    watcher3: any;
    watcher4: any;
    watcher5: any;
    watcher6: any;
    watcher7: any;
    watcher8: any;
    watcher9: any;
    constructor(
        private $log: ng.ILogService,
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService,
        private $state: StateService,
        private $stateParams: StateParams,
        private $window: ng.IWindowService, // used in view
        private hotkeys: HotkeysProvider, // used in view
        private api: ApiService,
        private contacts: ContactsService,
        private contactFilter: ContactFilterService,
        private contactsTags: ContactsTagsService,
        private session: SessionService, // used in view
        private users: UsersService,
    ) {
        this.pagination = pagination;
        this.contacts.clearSelectedContacts();
        this.allSelected = false;
        this.data = [];
        this.listLoadCount = 0;
        this.loading = false;
        this.meta = {};
        this.models = {
            addTags: {
                newTag: '',
            },
        };
        this.page = 0;
        this.totalContactCount = 0;
        this.pageSize = defaultTo(25, this.users.getCurrentOptionValue('page_size_contacts'));
    }
    $onInit(): void {
        this.page = defaultTo(1, this.$stateParams.page);
        this.load(this.page);

        this.watcher = this.$rootScope.$on('contactCreated', () => {
            this.pageChange();
            this.contacts.clearSelectedContacts();
        });

        this.watcher2 = this.$rootScope.$on('accountListUpdated', () => {
            this.refreshFiltersAndTags().then(() => {
                this.pageChange();
            });
            this.contacts.clearSelectedContacts();
        });

        this.watcher3 = this.$rootScope.$on('contactsFilterChange', () => {
            this.pageChange();
            this.contacts.clearSelectedContacts();
        });

        this.watcher4 = this.$rootScope.$on('contactsTagsChange', () => {
            this.pageChange();
            this.contacts.clearSelectedContacts();
        });

        this.watcher5 = this.$rootScope.$on('contactTagsAdded', () => {
            this.contacts.clearSelectedContacts();
        });

        this.watcher9 = this.$rootScope.$on('contactHidden', (e, id) => {
            this.data = reject({ id: id }, this.data);
            // handle repagination here (like tasks does)
            this.contacts.clearSelectedContacts();
        });

        this.watcher6 = this.$rootScope.$on('contactTagDeleted', () => {
            this.contacts.clearSelectedContacts();
        });

        this.watcher7 = this.$rootScope.$on('taskAdded', () => {
            this.contacts.clearSelectedContacts();
        });

        this.watcher8 = this.$rootScope.$on('taskLogged', () => {
            this.contacts.clearSelectedContacts();
        });
    }
    $onDestroy(): void {
        this.watcher();
        this.watcher2();
        this.watcher3();
        this.watcher4();
        this.watcher5();
        this.watcher6();
        this.watcher7();
        this.watcher8();
        this.watcher9();
    }
    load(page = 1): ng.IPromise<any> {
        this.loading = true;

        let currentCount;
        this.meta = {};
        this.data = null;
        this.listLoadCount++;
        currentCount = angular.copy(this.listLoadCount);
        this.page = page;
        return this.api
            .get({
                url: 'contacts',
                data: {
                    filter: this.contactFilter.buildFilterParams(),
                    page: page,
                    per_page: this.pageSize,
                    include:
                        'addresses,people,people.facebook_accounts,people.phone_numbers,people.email_addresses,user',
                    fields: {
                        contacts:
                            'addresses,name,status,square_avatar,send_newsletter,pledge_currency,' +
                            'pledge_frequency,pledge_received,uncompleted_tasks_count,tag_list,pledge_amount,' +
                            'people,created_at,late_at,primary_person,starred_at,user',
                        people: 'deceased,email_addresses,facebook_accounts,first_name,last_name,phone_numbers',
                        addresses:
                            'city,geo,historic,primary_mailing_address,postal_code,state,source,street,updated_at',
                        email_addresses: 'email,historic,primary',
                        phone_numbers: 'historic,location,number,primary',
                        facebook_accounts: 'username',
                        users: 'first_name,last_name',
                    },
                    sort: 'name',
                },
                overrideGetAsPost: true,
            })
            .then((data: any) => {
                /* istanbul ignore next */
                this.$log.debug('contacts page ' + data.meta.pagination.page, data);
                if (currentCount !== this.listLoadCount) {
                    return;
                }
                this.meta = data.meta;
                if (data.length === 0) {
                    this.getTotalCount();
                    this.loading = false;
                    return;
                }
                const fixedData = this.contacts.fixPledgeAmountAndFrequencies(data);
                this.data = this.sortPeoplePrimary(fixedData);
                this.loading = false;
                return this.data;
            });
    }
    changeToFlowsView(): void {
        this.$state.go('contacts.flows');
        this.users.saveOption('contacts_view', 'flows');
    }
    private sortPeoplePrimary(data): any[] {
        // put primary on top
        const notPrimary = (contact, person) => contact.primary_person && person.id !== contact.primary_person.id;
        const sortNotPrimary = (contact) => sortBy((person) => notPrimary(contact, person), contact.people);
        return map(
            (contact) =>
                assign(contact, {
                    people: sortNotPrimary(contact),
                }),
            data,
        );
    }
    getSelectedContacts() {
        if (this.contacts.selectedContactIds.length > this.data.length) {
            return map((id) => ({ id: id }), this.contacts.selectedContactIds);
        }
        return reduce(
            (result, contact) => {
                const hasId = includes(contact.id, this.contacts.selectedContactIds);
                return hasId ? concat(result, contact) : result;
            },
            [],
            this.data,
        );
    }
    selectAllContacts(all = true): ng.IPromise<void> {
        if (all) {
            this.allSelected = true; // for reactive visuals
            return this.getCompleteFilteredList()
                .then((data) => {
                    this.allSelected = false;
                    this.contacts.selectedContactIds = map('id', data);
                })
                .catch(() => {
                    this.allSelected = false;
                });
        } else {
            this.contacts.selectedContactIds = map('id', this.data);
            this.$q.resolve();
        }
    }
    pageSizeChange(_size): void {
        this.$state.go('contacts', { page: 1 }, { reload: true });
    }
    pageChange(page = 1): void {
        if (this.$stateParams.page === 1 && page === 1) {
            this.load();
        }
        this.$state.go('contacts', { page: page }, { reload: false });
    }
    filterStarred(): void {
        if (this.contactFilter.params.starred) {
            delete this.contactFilter.params.starred;
        } else {
            this.contactFilter.params.starred = true;
        }
        this.$rootScope.$emit('contactsFilterChange');
    }
    onContactsHide(contacts): void {
        this.data = pullAllBy('id', contacts, this.data);
        this.contacts.clearSelectedContacts();
    }
    private refreshFiltersAndTags(): ng.IPromise<any> {
        return this.$q.all([this.contactFilter.load(true), this.contactsTags.load()]);
    }
    private getTotalCount(): ng.IPromise<any> {
        return this.contacts.getTotalCount().then((data: number) => {
            this.totalContactCount = data;
        });
    }
    private getCompleteFilteredList(): ng.IPromise<any> {
        return this.api.get({
            url: 'contacts',
            data: {
                filter: this.contactFilter.buildFilterParams(),
                fields: {
                    contacts: '',
                },
                per_page: 25000,
            },
            overrideGetAsPost: true,
        });
    }
}

const ContactList = {
    controller: ListController,
    template: require('./list.html'),
    bindings: {
        selected: '=',
    },
};

export default angular
    .module('mpdx.contacts.list.component', ['cfp.hotkeys', api, contacts, contactFilter, contactsTags, session, users])
    .component('contactsList', ContactList).name;
