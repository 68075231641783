import 'expose-loader?$!jquery'; // import before angular to replace jqlite
import * as angular from 'angular';
import acceptInvite from './acceptInvite/acceptInvite.component';
import app from './app.directive';
import appConfig from './app.config';
import appRun from './app.run';
import bottom from './bottom/bottom.module';
import coaches from './coaches/coaches.module';
import common from './common/common.module';
import contacts from './contacts/contacts.module';
import handoff from './handoff/handoff.component';
import home from './home/home.module';
import login from './login/login.component';
import menu from './menu/menu.module';
import preferences from './preferences/preferences.module';
import reports from './reports/reports.module';
import root from './root/root.component';
import setup from './setup/setup.module';
import tasks from './tasks/tasks.module';
import tools from './tools/tools.module';
import unavailable from './unavailable/unavailable.component';
import unsupported from './unsupported/unsupported.module';
import vendor from './vendor.module';

export default angular
    .module('mpdx', [
        acceptInvite,
        app,
        bottom,
        coaches,
        common,
        contacts,
        handoff,
        home,
        login,
        menu,
        preferences,
        reports,
        root,
        setup,
        tasks,
        tools,
        unavailable,
        unsupported,
        vendor,
    ])
    .config(appConfig)
    .run(appRun).name;

angular.module('infinite-scroll').value('THROTTLE_MILLISECONDS', 500);
