import 'angular-block-ui';
import api, { ApiService } from '../../../../common/api/api.service';
import contacts, { ContactsService } from '../../../../contacts/contacts.service';

class EmailController {
    blockUI: IBlockUIService;
    emails: any;
    constructor(
        blockUI: IBlockUIService,
        private api: ApiService,
        private gettext: ng.gettext.gettextFunction,
        private contactIds: any[],
        private contacts: ContactsService,
    ) {
        this.blockUI = blockUI.instances.get('contact-export');

        this.getEmails();
    }
    getEmails() {
        this.blockUI.start();
        const errorMessage = this.gettext('Unable to retrieve contacts. Please try again.');
        return this.api
            .get('contacts', {
                filter: {
                    account_list_id: this.api.account_list_id,
                    ids: this.contactIds.join(','),
                },
                include: 'people,people.email_addresses',
                fields: {
                    contacts: 'people',
                    people: 'deceased,email_addresses,optout_enewsletter',
                    email_addresses: 'email,primary',
                },
                errorMessage: errorMessage,
                overrideGetAsPost: true,
                per_page: 25000,
            })
            .then((data) => {
                this.emails = this.contacts.mapEmails(data);
                this.blockUI.reset();
            })
            .catch((ex) => {
                this.blockUI.reset();
                throw ex;
            });
    }
}

export default angular
    .module('mpdx.tools.appeals.show.email.controller', ['blockUI', api, contacts])
    .controller('exportAppealsEmailsController', EmailController).name;
