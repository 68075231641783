import 'angular-gettext';
import * as _ from 'lodash';
import { includes } from 'lodash/fp';
import importDonation, { ImportDonationService } from '../../donation/donation.service';
import modal, { ModalService } from '../../../../common/modal/modal.service';
import serverConstants, { ServerConstantsService } from '../../../../common/serverConstants/serverConstants.service';

class HeadersController {
    mappedHeaders: any[];
    unmappedHeaders: any[];
    missingFields: string[];
    selected: any;
    constructor(
        private gettextCatalog: ng.gettext.gettextCatalog,
        private importDonation: ImportDonationService,
        private modal: ModalService,
        private serverConstants: ServerConstantsService,
    ) {
        this.mappedHeaders = [];
        this.unmappedHeaders = [];
        this.missingFields = [];
    }
    $onInit() {
        this.updateHeaders();
    }
    updateHeaders() {
        let objectData = this.serverConstants.data.donation_import.supported_headers;
        this.mappedHeaders = Object.keys(objectData).map((key) => ({
            key: key,
            value: objectData[key],
            disabled: false,
        }));
    }
    handleUpdateHeaders(headerKey: string, selected) {
        this.updateHeaders();
        this.importDonation.data.file_headers_mappings[headerKey] = selected.toLowerCase().replace(/ /g, '_');
    }
    containsName() {
        return (
            (includes('first_name', this.mappedHeaders) && includes('last_name', this.mappedHeaders)) ||
            includes('full_name', this.mappedHeaders)
        );
    }
    async save() {
        const requiredFields = ['account_name', 'account_number', 'remote_id', 'donation_date', 'currency', 'amount'];
        const fieldMapHeader = _.invert(angular.copy(this.importDonation.data.file_headers_mappings));
        if (fieldMapHeader.donation_id) {
            fieldMapHeader.remote_id = fieldMapHeader.donation_id;
        }
        this.missingFields = requiredFields.filter((field) => !(field in fieldMapHeader));
        if (this.missingFields.length > 0) return false;

        this.importDonation.values_to_constants_mappings = {};
        this.importDonation.donorAccountCollectionForDisplay = {};
        this.importDonation.designationAccountCollectionForDisplay = {};
        this.importDonation.appealCollectionForDisplay = [];
        await this.importDonation.save();
    }
    back(): ng.IPromise<void> {
        const message = this.gettextCatalog.getString(
            'Are you sure you want to navigate back to the upload step? You will lose all unsaved progress.',
        );

        return this.modal.confirm(message).then(() => {
            this.importDonation.back();
        });
    }
    isOptionDisabled(selected, item) {
        if (item.value === selected) {
            item.disabled = true;
        }
    }
    selectedObject(headerKey: string) {
        let key = this.serverConstants.data.donation_import.list_headers[headerKey];
        if (key && !this.importDonation.data.file_headers_mappings[key]) {
            this.importDonation.data.file_headers_mappings[
                this.serverConstants.data.donation_import.supported_headers[key].toLowerCase().replace(/ /g, '_')
            ] = key;
        }
        return this.serverConstants.data.donation_import.supported_headers[key];
    }
}

const Headers = {
    controller: HeadersController,
    template: require('./headers.html'),
};

export default angular
    .module('mpdx.preferences.donation.headers.component', ['gettext', importDonation, modal, serverConstants])
    .component('importDonationHeaders', Headers).name;
