import abbreviate from './abbreviate/abbreviate.filter';
import accountingCurrencyFilter from './accountingCurrency/accountingCurrency.filter';
import addressFinder from './addressFinder/addressFinder.module';
import announcements from './announcements/announcements.module';
import autofocus from './autofocus/autofocus.directive';
import avatar from './avatar/avatar.component';
import chosen from './chosen/chosen.directive';
import collectionExistedSelector from './collectionExistedSelector/collectionExistedSelector.component';
import collectionSelector from './collectionSelector/collectionSelector.component';
import contactsSelector from './contactsSelector/selector.component';
import cover from './cover/cover.component';
import datetimepicker from './datetimepicker/datetimepicker.component';
import designationAccountsSelector from './designationAccounts/selector/selector.component';
import drawer from './sideDrawer/drawer.component';
import emailExport from './emailExport/email.controller';
import faCheckbox from './faCheckbox/faCheckbox.component';
import filters from './filters/filters.module';
import goal from './goal/goal.component';
import links from './links/links.module';
import locale from './locale/locale.filter';
import modal from './modal/modal.module';
import momentFilter from './moment/moment.filter';
import pagination from './pagination/pagination.module';
import rawNumber from './rawNumber/rawNumber.directive';
import search from './search/search.module';
import selectableList from './selectableList/selectableList.component';
import sortHeaderCaret from './sortHeaderCaret/caret.component';
import sourceToStr from './sourceToStr/sourceToStr.filter';
import stripMpdx from './stripMpdx/stripMpdx.filter';
import tagSelector from './tagSelector/tagSelector.component';
import threePartDateDisplay from './threePartDateDisplay/display.component';
import threePartDatePicker from './threePartDatePicker/picker.component';
import twoPartDateDisplay from './twoPartDateDisplay/display.component';

export default angular.module('mpdx.common', [
    abbreviate,
    accountingCurrencyFilter,
    addressFinder,
    announcements,
    autofocus,
    avatar,
    chosen,
    collectionExistedSelector,
    collectionSelector,
    contactsSelector,
    cover,
    datetimepicker,
    designationAccountsSelector,
    drawer,
    emailExport,
    faCheckbox,
    filters,
    goal,
    links,
    locale,
    modal,
    momentFilter,
    pagination,
    rawNumber,
    search,
    selectableList,
    sortHeaderCaret,
    sourceToStr,
    stripMpdx,
    tagSelector,
    threePartDateDisplay,
    threePartDatePicker,
    twoPartDateDisplay,
]).name;
