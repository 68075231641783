import { defaultTo } from 'lodash/fp';
import modal, { ModalService } from '../modal/modal.service';
import modalController from './modal/modal.controller';

class CollectionExistedSelectorController {
    buttonText: string;
    displayText: string;
    itemName: string;
    search: any;
    searchText: string;
    select: any;
    constructor(private modal: ModalService) {}
    openModal() {
        this.modal.open({
            template: require('./modal/modal.html'),
            controller: 'collectionExistedSelectionModalController',
            locals: {
                collectionSearch: (text) => this.collectionSearch(text),
                itemName: this.itemName,
                searchText: this.collectionSearchText(),
                select: (item) => this.collectionSelect(item),
            },
        });
    }
    collectionSearch(text) {
        return this.search({ text: text });
    }
    collectionSelect(item) {
        return this.select({ item: item });
    }
    collectionSearchText() {
        return defaultTo(this.displayText, this.searchText) || '';
    }
    remove() {
        this.select({ item: null });
    }
}

const CollectionExistedSelector = {
    template: '<a ng-transclude ng-click="$ctrl.openModal()"></a>',
    controller: CollectionExistedSelectorController,
    transclude: true,
    bindings: {
        buttonText: '@',
        displayText: '<',
        search: '&',
        itemName: '@',
        searchText: '<',
        select: '&',
        allowRemove: '<',
    },
};

export default angular
    .module('mpdx.common.collectionExistedSelector.component', [modal, modalController])
    .component('collectionExistedSelector', CollectionExistedSelector).name;
