interface IModalOptions extends mgcrea.ngStrap.modal.IModalOptions {
    onHide?: never;
}
export class ModalService {
    defaultParams: IModalOptions;
    constructor(private $modal: mgcrea.ngStrap.modal.IModalService, private $q: ng.IQService) {
        this.defaultParams = {
            placement: 'center',
            controllerAs: '$ctrl',
            locals: {},
        };
    }
    open(params: IModalOptions, deferred: ng.IDeferred<any> = this.$q.defer()): ng.IPromise<any> {
        let openParams: mgcrea.ngStrap.modal.IModalOptions = {
            ...this.defaultParams,
            ...params,
            onHide: () => deferred.resolve(),
        };
        openParams.locals.deferred = deferred;
        this.$modal(openParams);
        return deferred.promise;
    }
    confirm(message: string, title: string = null): ng.IPromise<any> {
        const params = {
            template: require('./confirm/confirm.html'),
            controller: 'confirmController',
            locals: {
                message,
                title,
            },
        };
        return this.open(params);
    }
    info(message: string, title: string = null): ng.IPromise<any> {
        const params = {
            template: require('./info/info.html'),
            controller: 'infoController',
            locals: {
                message,
                title,
            },
        };
        return this.open(params);
    }
    cover(message: string, title: string = null): mgcrea.ngStrap.modal.IModal {
        const params = {
            ...this.defaultParams,
            template: require('./cover/cover.html'),
            controller: 'coverController',
            locals: {
                message,
                title,
            },
        };
        return this.$modal(params);
    }
}

export default angular.module('mpdxApp.services.modal', ['mgcrea.ngStrap']).service('modal', ModalService).name;
