import * as Bowser from 'bowser';
import { StateService } from '@uirouter/core';
import uiRouter from '@uirouter/angularjs';
import unsupported, { UnsupportedService } from './unsupported.service';

export class UnsupportedController {
    isAndroid: boolean;
    isIos: boolean;
    mediaDarkIcon: string = process.env.MEDIA_DARK_ICON;
    constructor(
        private $state: StateService,
        private $window: ng.IWindowService,
        private unsupported: UnsupportedService,
    ) {}
    $onInit() {
        if (!this.unsupported.showUnsupported()) {
            this.$state.go('home');
            return;
        }
        const browser = Bowser.getParser(this.$window.navigator.userAgent);
        this.isAndroid = browser.getOSName() === 'Android';
        this.isIos = browser.getOSName() === 'iOS';
    }
}

const Unsupported = {
    template: require('./unsupported.html'),
    controller: UnsupportedController,
};

export default angular
    .module('mpdx.unsupported.component', [uiRouter, unsupported])
    .component('unsupported', Unsupported).name;
