import { assign, find, map, reduce } from 'lodash/fp';
import api, { ApiService } from '../api/api.service';

export class FinancialAccountsService {
    balance: number;
    data: any;
    dataByOrganization: any;
    constructor(private $q: ng.IQService, private api: ApiService) {
        this.balance = 0;
        this.data = [];
        this.dataByOrganization = [];
    }
    load(reset = false): ng.IPromise<any> {
        if (!reset && this.data.length > 0) {
            return this.$q.resolve(this.data);
        }

        return this.api
            .get(`account_lists/${this.api.account_list_id}/financial_accounts`, {
                per_page: 10000,
                include: 'organization',
                fields: {
                    financial_accounts:
                        'code,name,active,organization,balance,currency,' +
                        'converted_currency,converted_balance,last_download_at',
                    organizations: 'name',
                },
            })
            .then((data) => {
                this.data = data;
                this.dataByOrganization = reduce(
                    (result, financialAccount: { organization: { id: string } }) => {
                        let organization = find({ id: financialAccount.organization.id }, result);
                        if (!organization) {
                            organization = angular.copy(financialAccount.organization);
                            organization.financialAccounts = [];
                            result.push(organization);
                        }
                        organization.financialAccounts.push(financialAccount);
                        return result;
                    },
                    [],
                    this.data,
                );
                this.updateBalance();
                return this.data;
            });
    }
    get(id: string): ng.IPromise<any> {
        return this.api.get(`account_lists/${this.api.account_list_id}/financial_accounts/${id}`, {
            include: 'organization',
            fields: {
                financial_accounts:
                    'code,name,active,organization,balance,currency,' + 'converted_currency,converted_balance',
                organizations: 'name',
            },
        });
    }
    loadCategories(id: string): ng.IPromise<any> {
        return this.api
            .get(`account_lists/${this.api.account_list_id}/financial_accounts/${id}`, {
                include: 'categories',
                fields: {
                    financial_accounts: 'categories',
                    financial_account_entry_categories: 'code,name',
                },
            })
            .then((data) => {
                return data.categories;
            });
    }
    save(financialAccount: any): ng.IPromise<any> {
        return this.api
            .put({
                url: `account_lists/${this.api.account_list_id}/financial_accounts/${financialAccount.id}`,
                data: financialAccount,
                type: 'financial_accounts',
            })
            .then((data) => {
                this.data = map((financialAccount) => {
                    return data.id === financialAccount.id ? assign(financialAccount, data) : financialAccount;
                }, this.data);
                this.updateBalance();
                return data;
            });
    }
    updateBalance(): void {
        this.balance = reduce(
            (result, financialAccount) =>
                result + (financialAccount.active ? -parseFloat(financialAccount.converted_balance) : 0),
            0,
            this.data,
        );
    }
}

export default angular
    .module('mpdx.common.financialAccounts.service', [api])
    .service('financialAccounts', FinancialAccountsService).name;
