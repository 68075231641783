import { round } from 'lodash/fp';
import importDonation, { ImportDonationService } from '../donation.service';
import serverConstants, { ServerConstantsService } from '../../../../common/serverConstants/serverConstants.service';

class UploadController {
    maxSize: number;
    maxSizeInMB: number;
    constructor(private importDonation: ImportDonationService, private serverConstants: ServerConstantsService) {
        this.maxSize = this.serverConstants.data.donation_import.max_file_size_in_bytes;
        this.maxSizeInMB = round(this.maxSize / 1000000);
    }
    downloadTemplate() {
        const csvLink = 'https://partneressentials-public-assets.s3.amazonaws.com/PE_Contact_Import_Template_CSV.csv';
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', csvLink);
        link.setAttribute('download', 'PE_Donation_Import_Template.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}

const Upload: ng.IComponentOptions = {
    controller: UploadController,
    template: require('./upload.html'),
};

export default angular
    .module('mpdx.preferences.donation.upload.component', [importDonation, serverConstants])
    .component('importDonationUpload', Upload).name;
