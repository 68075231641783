import 'angular-block-ui';
import { defaultTo } from 'lodash/fp';
import api, { ApiService } from '../../../../common/api/api.service';
import appealsShow, { AppealsShowService } from '../show.service';
import users, { UsersService } from '../../../../common/users/users.service';

class GivenController {
    appealId: string;
    blockUI: IBlockUIService;
    data: any[];
    meta: any;
    page: number;
    pageSize: number;
    watcher: () => void;
    constructor(
        private $log: ng.ILogService,
        private $rootScope: ng.IRootScopeService,
        private appealsShow: AppealsShowService,
        private api: ApiService,
        blockUI: IBlockUIService,
        users: UsersService,
    ) {
        this.blockUI = blockUI.instances.get('appealShow');
        this.pageSize = defaultTo(25, users.getCurrentOptionValue('page_size_appeal'));
        this.page = 1;
    }
    $onInit() {
        this.watcher = this.$rootScope.$on('appeal:refresh', () => {
            this.load();
        });
    }
    $onDestroy() {
        this.watcher();
    }
    $onChanges() {
        this.appealsShow.deselectAll();
        if (this.appealId) {
            this.load();
        }
    }
    load(page: number = this.page): ng.IPromise<void> {
        this.blockUI.start();
        return this.api
            .get(`account_lists/${this.api.account_list_id}/pledges`, {
                include: 'contact,donations',
                page: page,
                per_page: this.pageSize,
                fields: {
                    contacts: 'name',
                    donations: 'appeal_amount,converted_appeal_amount,currency,converted_currency,donation_date',
                },
                filter: {
                    appeal_id: this.appealId,
                    status: 'processed',
                },
                sort: 'contact.name',
            })
            .then((data: any) => {
                /* istanbul ignore next */
                this.$log.debug(`pledges processed page ${page}`, data);
                const fixedData = this.appealsShow.fixPledgeAmount(data);
                this.data = this.appealsShow.addSymbols(fixedData);
                this.meta = data.meta;
                this.page = page;
                this.blockUI.reset();
            });
    }
    pageSizeChange(size) {
        this.pageSize = size;
        this.load(1);
    }
}

const Given: ng.IComponentOptions = {
    template: require('./given.html'),
    controller: GivenController,
    bindings: {
        appealId: '<',
    },
};

export default angular
    .module('mpdx.tools.appeals.show.given.component', ['blockUI', appealsShow, api, users])
    .component('appealGiven', Given).name;
