import { StateService } from '@uirouter/core';
import pkceChallenge from 'pkce-challenge';
import rewritehandoff, { RewriteHandoffService } from '../rewritehandoff/rewritehandoff.service';
import uiRouter from '@uirouter/angularjs';

export class ApiOauthService {
    signingIn = false;
    client_id = process.env.API_OAUTH_CLIENT_ID;
    redirect_uri = `${window.location.origin}/login`;
    authCode = null;
    codeChallenge = null;
    codeVerifier = null;
    constructor(
        private $http: any, // Custom get config
        private $location: ng.ILocationService,
        private $window: ng.IWindowService,
        private $state: StateService,
        private $rootScope: ng.IRootScopeService,
        private rewritehandoff: RewriteHandoffService,
    ) {}
    handleRedirect() {
        const func = async () => {
            if (process.env.AUTH_PROVIDER === 'API_OAUTH') {
                const authCode = this.$location.search()?.code;

                // Don't continue if code isn't present
                if (!authCode) return false;

                this.authCode = authCode;

                await this.signIn();
                return true;
            }
        };

        return func();
    }
    signIn() {
        const func = async () => {
            // ========================
            // AuthenicateTokens
            // - Function used to send access_token to mpdx API to request JWT and redirect user to homepage
            // ========================
            const authenicateTokens = async (tokens: any) => {
                try {
                    let accessToken = tokens?.access_token;
                    // Save tokens to local storage
                    this.$window.localStorage.setItem('apioauth_tokens', JSON.stringify(tokens));

                    const resp = await this.$http.post(
                        `${process.env.API_URL}/user/authenticate`,
                        {
                            data: {
                                type: 'authenticate',
                                attributes: {
                                    access_token: accessToken,
                                    provider: 'api_oauth',
                                },
                            },
                        },
                        {
                            headers: {
                                Accept: 'application/vnd.api+json',
                                'Content-Type': 'application/vnd.api+json',
                            },
                            skipAuthorization: true,
                        },
                    );

                    const jsonWebToken = resp?.data?.data?.attributes?.json_web_token;

                    if (!jsonWebToken) {
                        throw new Error('JWT isnt present in request response');
                    }

                    this.$window.localStorage.setItem('token', jsonWebToken);
                    const redirect = angular.copy(this.$window.localStorage.getItem('redirect'));
                    const params = angular.copy(JSON.parse(this.$window.localStorage.getItem('params')) || {});
                    this.$window.localStorage.removeItem('redirect');
                    this.$window.localStorage.removeItem('params');
                    this.$window.localStorage.removeItem('apioauth_codeVerifier');
                    if (redirect) {
                        this.$location.search(params);
                        this.$location.path(redirect);
                    } else {
                        this.$state.go('home', params, { reload: true });
                    }
                    this.$rootScope.$apply();
                } catch (err) {
                    throw new Error(err.message);
                }
            };

            try {
                if (process.env.AUTH_PROVIDER !== 'API_OAUTH') {
                    throw new Error('AUTH_PROVIDER env is not setup for API_OAUTH');
                }
                if (!process.env.API_OAUTH_ISSUER || !this.client_id) {
                    throw new Error('API_OAUTH_ISSUER or API_OAUTH_CLIENT_ID envs not defined');
                }
                // ========================
                // Fetch auth code or Refresh tokens
                // - Code used to redirect user to sign in via Api Oauth
                //   or fetch refresh tokens and fetch new access_token.
                // ========================

                // If no auth_code, user needs to authenicate via Api Oauth or has refresh_tokens
                if (!this.authCode) {
                    // Check if user has refresh_tokens from previous session
                    const apioauthTokensText = this.$window.localStorage.getItem('apioauth_tokens');
                    if (apioauthTokensText) {
                        const apioauthTokensJson = JSON.parse(apioauthTokensText);
                        try {
                            const fetchAccessTokens = await this.$http.post(
                                `${process.env.API_OAUTH_ISSUER}/oauth/token`,
                                `refresh_token=${apioauthTokensJson.refresh_token}&client_id=${this.client_id}&redirect_uri=${this.redirect_uri}&grant_type=refresh_token&confidential=false`,
                                {
                                    headers: {
                                        Accept: 'application/json',
                                        'Content-Type': 'application/x-www-form-urlencoded',
                                    },
                                },
                            );

                            if (!fetchAccessTokens?.data?.access_token) {
                                throw new Error('access_token not present in request response');
                            }
                            await authenicateTokens(fetchAccessTokens?.data);
                            return;
                        } catch (err) {
                            throw new Error(err.message);
                        }
                    }

                    // Redirect user to ApiOauth
                    const { code_challenge: codeChallenge, code_verifier: codeVerifier } = await pkceChallenge();
                    this.$window.localStorage.setItem('apioauth_codeVerifier', codeVerifier);
                    this.$window.location.href = `${process.env.API_OAUTH_ISSUER}/oauth/authorize?client_id=${this.client_id}&redirect_uri=${this.redirect_uri}&response_type=code&code_challenge=${codeChallenge}&code_challenge_method=S256`;

                    return;
                }

                // ========================
                // Fetch access_token with auth_code
                // - Code used to fetch an access_token using the auth_code.
                //   User has auth_code, meaning the user has returned from ApiOauth with auth code
                // ========================

                let codeVerifier = this.$window.localStorage.getItem('apioauth_codeVerifier');
                const fetchAccessTokens = await this.$http.post(
                    `${process.env.API_OAUTH_ISSUER}/oauth/token`,
                    `code=${this.authCode}&client_id=${this.client_id}&redirect_uri=${this.redirect_uri}&grant_type=authorization_code&code_verifier=${codeVerifier}&confidential=false`,
                    {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                    },
                );
                if (!fetchAccessTokens?.data?.access_token) {
                    throw new Error('access_token not present in request response');
                }
                await authenicateTokens(fetchAccessTokens?.data);
            } catch (err) {
                await this.signOut();
                this.$state.go('logout');
                this.$rootScope.$apply();
                throw err;
            }
        };
        return func();
    }
    signOut() {
        this.$window.localStorage.removeItem('apioauth_codeVerifier');
        this.$window.localStorage.removeItem('apioauth_tokens');
        this.$window.localStorage.removeItem('token');
        if (this.rewritehandoff.hasLoggedintoRewriteApp()) {
            this.$window.location.href = `${this.rewritehandoff.newOrigin}/logout`;
        }
    }
}

export default angular
    .module('mpdx.common.apioauth.service', [uiRouter, rewritehandoff])
    .service('apioauth', ApiOauthService).name;
