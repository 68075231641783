import '@uirouter/angularjs';
import 'angular-gettext';
import * as Bowser from 'bowser';
import * as moment from 'moment';
import { contains, filter, get, isEqual, map } from 'lodash/fp';
import { StateService } from '@uirouter/core';
import { v1 as uuidv1 } from 'uuid';
import accounts, { AccountsService } from '../../common/accounts/accounts.service';
import alerts, { AlertsService } from '../../common/alerts/alerts.service';
import api, { ApiService } from '../../common/api/api.service';
import emptyToNull from '../../common/fp/emptyToNull';
import joinComma from '../../common/fp/joinComma';
import modal, { ModalService } from '../../common/modal/modal.service';
import removeObjectNulls from '../../common/fp/removeObjectNulls';

export class AppealsService {
    allExcludes: string[];
    constructor(
        private $rootScope: ng.IRootScopeService,
        private $state: StateService,
        private $window: ng.IWindowService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private accounts: AccountsService,
        private alerts: AlertsService,
        private api: ApiService,
        private modal: ModalService,
    ) {
        this.allExcludes = [
            'joinedTeam3months',
            'specialGift3months',
            'increasedGiving3months',
            '30daysLate',
            'doNotAskAppeals',
        ];
    }
    addedMessage(count: number) {
        return this.gettextCatalog.getPlural(
            count,
            '1 contact successfully added to your appeal.',
            '{{$count}} contacts successfully added to your appeal.',
            {},
        );
    }
    removePledge(id: string): ng.IPromise<any> {
        const successMessage = this.gettextCatalog.getString('Successfully removed commitment from appeal');
        const errorMessage = this.gettextCatalog.getString('Unable to remove commitment from appeal');
        return this.api.delete(
            `account_lists/${this.api.account_list_id}/pledges/${id}`,
            undefined,
            successMessage,
            errorMessage,
        );
    }
    setPrimaryAppeal(appeal: any): ng.IPromise<any> {
        this.accounts.current.primary_appeal = { id: appeal.id };
        const successMessage = this.gettextCatalog.getString('Appeal successfully set to primary');
        const errorMessage = this.gettextCatalog.getString('Unable to set Appeal as primary');
        return this.accounts.saveCurrent(successMessage, errorMessage);
    }
    // do not remove from service per Partner Essentials-5706 as global req.
    // all defaults come from Partner Essentials-5706
    oneClickAppeal(): ng.IPromise<void | any> {
        const now = moment();
        const year = now.year();
        const appeal = {
            name: `${year} End of Year Ask`,
            amount: '0',
        };
        const statuses = ['active', 'null'];
        const fiveYears = moment(now).subtract(5, 'y').locale('en').format('YYYY-MM-DD');
        const nowFormatted = now.locale('en').format('YYYY-MM-DD');
        const includes = {
            donation: 'one',
            donation_date: `${fiveYears}..${nowFormatted}`,
        };
        return this.createWithModal({
            appeal: appeal,
            includes: includes,
            excludes: this.allExcludes,
            statuses: statuses,
        }).then((data) => this.onOneClickCreate(data));
    }
    createWithModal(params: any) {
        const createMsg = this.gettextCatalog.getString('Your appeal is being created.');
        const modal: any = this.modal.cover(createMsg);
        return this.create(params).then((data) => {
            modal.hide();
            return data;
        });
    }
    private onOneClickCreate(data: any): void {
        const browser = Bowser.getParser(this.$window.navigator.userAgent);
        const isMobile = browser.getPlatform().type === 'mobile';
        if (isMobile) {
            const msg = this.gettextCatalog.getString(
                'Your appeal has been created, check it out in the Partner Essentials app.',
            );
            this.modal.info(msg);
        } else {
            this.$state.go('tools.appeals.show', {
                appealId: data.id,
                tour: 'start',
            });
        }
    }
    create({
        appeal,
        tags = [],
        statuses = [],
        excludes = [],
        includes = {},
    }: {
        appeal: any;
        tags?: string[];
        statuses?: string[];
        excludes?: string[];
        includes?: any;
    }): ng.IPromise<any> {
        const newAppeal = {
            ...appeal,
            account_list: { id: this.api.account_list_id },
        };
        const inclusionFilter = this.buildInclusionFilter({
            includes,
            tags,
            statuses,
        });
        const exclusionFilter = this.buildExclusionFilter(excludes);
        if (!isEqual(inclusionFilter, {})) {
            newAppeal.inclusion_filter = inclusionFilter;
        }
        if (!isEqual(exclusionFilter, {})) {
            newAppeal.exclusion_filter = exclusionFilter;
        }
        return this.api.post({ url: 'appeals', data: newAppeal });
    }
    buildInclusionFilter({ includes, tags, statuses }: { includes: {}; tags: string[]; statuses: string[] }): {} {
        const defaultFilter = {
            account_list_id: this.api.account_list_id,
            any_tags: true,
        };
        const inclusionFilter = removeObjectNulls({
            ...defaultFilter,
            tags: emptyToNull(joinComma(tags)),
            status: emptyToNull(joinComma(statuses)),
            ...includes,
        });
        return isEqual(inclusionFilter, defaultFilter) ? {} : inclusionFilter;
    }
    buildExclusionFilter(excludes: string[] = []): {} {
        const today = moment().locale('en').format('YYYY-MM-DD');
        const threeMonthsAgo = moment().startOf('month').subtract(3, 'months').locale('en').format('YYYY-MM-DD');

        return removeObjectNulls({
            started_giving_range: contains('joinedTeam3months', excludes) ? `${threeMonthsAgo}..${today}` : null,
            gave_more_than_pledged_range: contains('specialGift3months', excludes)
                ? `${threeMonthsAgo}..${today}`
                : null,
            pledge_amount_increased_range: contains('increasedGiving3months', excludes)
                ? `${threeMonthsAgo}..${today}`
                : null,
            pledge_late_by: contains('30daysLate', excludes) ? '30_90' : null,
            no_appeals: contains('doNotAskAppeals', excludes) ? true : null,
        });
    }
    search(keyword: string): ng.IPromise<any> {
        return this.api.get({
            url: 'appeals',
            data: {
                filter: {
                    account_list_id: this.api.account_list_id,
                    wildcard_search: keyword,
                },
                fields: {
                    appeals: 'name',
                },
                per_page: 6,
            },
            overrideGetAsPost: true,
        });
    }
    bulkAddContacts(appealId: string, contactIds: string[]) {
        const data = map(
            (contactId) => ({
                id: uuidv1(),
                appeal: { id: appealId },
                contact: { id: contactId },
            }),
            contactIds,
        );
        const request = {
            url: 'appeals/appeal_contacts/bulk',
            data: data,
            type: 'appeal_contacts',
        };
        return this.api.post(request).then((data) => {
            const total = data.length;
            const existingCount = filter((rel) => get('[0].title', rel.errors) === 'has already been taken', data)
                .length;
            const excludedCount = filter((rel) => get('[0].title', rel.errors) === 'is on the Excluded List.', data)
                .length;
            const askedCount = total - excludedCount - existingCount;
            return this.afterAddCallback(askedCount, excludedCount, existingCount);
        });
    }
    afterAddCallback(askedCount: number, excludedCount: number, existingCount = 0) {
        const addedMsg = this.gettextCatalog.getPlural(
            askedCount,
            '1 contact successfully added to your appeal.',
            '{{$count}} contacts successfully added to your appeal.',
            {},
        );
        const existingMsg = existingCount
            ? this.gettextCatalog.getPlural(
                  existingCount,
                  '1 contact already exists on your appeal.',
                  '{{$count}} contacts already exists on your appeal.',
                  {},
              )
            : '';
        const excludedMsg = excludedCount
            ? this.gettextCatalog.getPlural(
                  excludedCount,
                  '1 contact was excluded from your appeal. Check Excluded contacts to see why.',
                  '{{$count}} contacts were excluded from your appeal. Check Excluded contacts to see why.',
                  {},
              )
            : '';
        const msg = addedMsg + (existingMsg ? ` ${existingMsg}` : '') + (excludedMsg ? ` ${excludedMsg}` : '');
        return this.modal.info(msg);
    }
    bulkAddExcludedContacts(appealId: string, contactIds: string[]) {
        const data = map(
            (contactId) => ({
                id: uuidv1(),
                appeal: { id: appealId },
                contact: { id: contactId },
                force_list_deletion: true,
            }),
            contactIds,
        );
        const request = {
            url: 'appeals/appeal_contacts/bulk',
            data: data,
            type: 'appeal_contacts',
        };
        return this.api.post(request).then((data) => {
            const addedMsg = this.addedMessage(data.length);
            this.$rootScope.$emit('appeal:refresh');
            return this.modal.info(addedMsg);
        });
    }
    searchContact(appealId: string, keyword: string): ng.IPromise<any> {
        return this.api.get({
            url: 'contacts',
            data: {
                filter: {
                    appeal: appealId,
                    reverse_appeal: true,
                    account_list_id: this.api.account_list_id,
                    wildcard_search: keyword,
                },
                fields: {
                    contacts: 'name',
                },
                per_page: 6,
                sort: 'name',
            },
            overrideGetAsPost: true,
        });
    }
    addContact(appealId: string, contact: any, forceListDeletion = false): ng.IPromise<any> {
        const data = {
            id: uuidv1(),
            appeal: {
                id: appealId,
            },
            contact: {
                id: contact.id,
            },
            force_list_deletion: forceListDeletion,
        };
        return this.api
            .post({
                url: `appeals/${appealId}/appeal_contacts`,
                data: data,
                type: 'appeal_contacts',
                overridePromise: true,
            })
            .then(() => {
                const successMessage = this.gettextCatalog.getString('Contact successfully added to appeal');
                this.alerts.addAlert(this.gettextCatalog.getString(successMessage));
                this.$rootScope.$emit('appeal:refresh');
                this.$rootScope.$emit('appeal:refreshCount');
            })
            .catch((ex) => {
                if (ex.status === 400) {
                    ex.status = 200; // needed to avoid infinite loop in tests
                    const message = this.gettextCatalog.getString(
                        'This contact has been previously excluded from this appeal. Are you certain you wish to add them?',
                    );
                    return this.modal.confirm(message).then(() => {
                        this.addContact(appealId, contact, true);
                    });
                } else {
                    const errorMessage = this.gettextCatalog.getString('Unable to add contact to appeal');
                    this.alerts.addAlert(this.gettextCatalog.getString(errorMessage), 'danger');
                }
            });
    }
}

export default angular
    .module('mpdx.tools.appeals.service', ['gettext', 'ui.router', accounts, alerts, api, modal])
    .service('appeals', AppealsService).name;
