import { isString } from 'lodash/fp';

function stripMpdx() {
    return function (val) {
        if (!isString(val)) {
            return '';
        }
        return val.replace('https://mpdx.org', '');
    };
}

export default angular.module('mpdx.common.stripMpdx', []).filter('stripMpdx', stripMpdx).name;
