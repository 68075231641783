import users, { UsersService } from '../../common/users/users.service';

export class HomeWelcomeController {
    greeting: string;
    appName = 'Partner Essentials';
    watcher: () => void;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private users: UsersService,
        private gettextCatalog: ng.gettext.gettextCatalog,
    ) {}
    $onInit(): void {
        if (process.env.APP_NAME) {
            this.appName = process.env.APP_NAME;
        }
        this.updateGreeting();
        this.watcher = this.$rootScope.$on('userUpdated', () => this.updateGreeting());
    }
    $onDestroy(): void {
        this.watcher();
    }
    updateGreeting(): void {
        const today = new Date();
        const currentHour = today.getHours();
        const name = this.users?.current?.first_name;
        this.greeting = name
            ? this.gettextCatalog.getString('Good Evening, {{ name }}.', { name })
            : this.gettextCatalog.getString('Good Evening,');

        if (currentHour < 12) {
            this.greeting = name
                ? this.gettextCatalog.getString('Good Morning, {{ name }}.', { name })
                : this.gettextCatalog.getString('Good Morning,');
        } else if (currentHour < 18) {
            this.greeting = name
                ? this.gettextCatalog.getString('Good Afternoon, {{ name }}.', { name })
                : this.gettextCatalog.getString('Good Afternoon,');
        }
    }
}

const Welcome = {
    controller: HomeWelcomeController,
    template: require('./welcome.html'),
};

export default angular.module('mpdx.home.welcome', [users]).component('homeWelcome', Welcome).name;
