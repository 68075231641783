import help, { HelpService } from '../common/help/help.service';

class CoachesController {
    constructor(private gettextCatalog: ng.gettext.gettextCatalog, private help: HelpService) {}
    $onInit() {
        if (this.help.variables().HS_COACHING_SUGGESTIONS?.length) {
            const suggestionsArray = [];
            this.help.variables().HS_COACHING_SUGGESTIONS.forEach((i) => {
                suggestionsArray.push(this.gettextCatalog.getString(i));
            });
            this.help.suggest(suggestionsArray);
        }
    }
}
const Coaches = {
    template: require('./coaches.html'),
    controller: CoachesController,
};

export default angular.module('mpdx.coaches.component', ['gettext', help]).component('coaches', Coaches).name;
