import {
    assign,
    compact,
    concat,
    defaultTo,
    difference,
    filter,
    find,
    findIndex,
    get,
    has,
    isArray,
    isBoolean,
    isEmpty,
    isEqual,
    isNil,
    isObject,
    isString,
    keys,
    map,
    omitBy,
    reduce,
    sortBy,
    toInteger,
} from 'lodash/fp';
import { convertTags } from '../fp/tags';
import { moreThanOneValue } from '../fp/arrays';
import { split } from '../fp/strings';
import api, { ApiService } from '../api/api.service';
import emptyToNull from '../fp/emptyToNull';
import reduceObject from '../fp/reduceObject';
import replaceAll from '../fp/replaceAll';

export class FiltersService {
    constructor(private $log: ng.ILogService, private $q: ng.IQService, private api: ApiService) {}
    count({ params, defaultParams, tags }: { params: any; defaultParams: any; tags: any }): number {
        return (
            filter((key) => !isEqual(params[key], defaultParams[key]), keys(params)).length +
            tags.selectedTags.length +
            tags.rejectedTags.length
        );
    }
    load({
        data,
        defaultParams,
        params,
        url,
    }: {
        data: any;
        defaultParams: any;
        params: any;
        url: string;
    }): ng.IPromise<any> {
        return data ? this.returnOriginalAsPromise(data, params, defaultParams) : this.getDataFromApi(url, params);
    }
    returnOriginalAsPromise(data: any, params: any, defaultParams: any): ng.IPromise<any> {
        return this.$q.resolve({
            data: data,
            params: params,
            defaultParams: defaultParams,
        });
    }
    getDataFromApi(url: string, params?: any): ng.IPromise<any> {
        return this.api
            .get(url, {
                filter: { account_list_id: this.api.account_list_id },
            })
            .then((response) => {
                const data = sortBy((filter: { id: string }) => toInteger(filter.id), defaultTo([], response));
                const defaultParams = this.makeDefaultParams(data);
                /* istanbul ignore next */
                this.$log.debug(url, data);
                return {
                    data: this.mutateData(data),
                    params: assign(angular.copy(defaultParams), params),
                    defaultParams: defaultParams,
                };
            });
    }
    makeDefaultParams(data: any): any {
        return reduce(
            (result, filter) => {
                if (filter.type === 'range' || filter.type === 'text') {
                    result[filter.name] = reduce(
                        (result, option) => {
                            if (has(option.id, filter.default_selection)) {
                                result[option.id] = filter.default_selection[option.id];
                            } else if (has('placeholder', option)) {
                                result[option.id] = option.placeholder;
                            } else {
                                result[option.id] = '';
                            }
                            return result;
                        },
                        {},
                        filter.options,
                    );
                } else {
                    result[filter.name] = this.splitToArr(filter.default_selection);
                }
                return result;
            },
            {},
            data,
        );
    }
    mutateData(data: any): any[] {
        return reduce(
            (result, filter) => {
                filter.default_selection = this.splitToArr(filter.default_selection);
                if (filter.parent !== null) {
                    let parentIndex = findIndex(
                        (parent) => parent.title === filter.parent && parent.type === 'container',
                        result,
                    );
                    if (parentIndex === -1) {
                        const parentObj = {
                            title: filter.parent,
                            type: 'container',
                            priority: filter.priority,
                            children: [filter],
                        };
                        result = concat(result, parentObj);
                    } else {
                        result[parentIndex].children = concat(result[parentIndex].children, filter);
                    }
                } else {
                    result = concat(result, filter);
                }
                return result;
            },
            [],
            data,
        );
    }
    splitToArr(selection: any): any {
        if (isString(selection)) {
            const defaultSpaceless = replaceAll(', ', ',', selection);
            return split(',', defaultSpaceless);
        } else if (isBoolean(selection)) {
            return [selection.toString()];
        }
        return selection;
    }
    fromStrings(params: any, filters: any): any {
        return reduceObject(
            (result, value, key) => {
                const filter = find({ name: key }, filters);
                const isMultiselect = get('type', filter) === 'multiselect';
                result[key] = isMultiselect ? split(',', value) : value;
                return result;
            },
            {},
            params,
        );
    }
    handleFilterChange(filter: any, params: any, defaultParams: any): [any, any] {
        return filter ? this.changeFilterIfExists(filter, params, defaultParams) : [filter, params];
    }
    private changeFilterIfExists(filter: any, params: any, defaultParams: any): [any, any] {
        const currentFilter = params[filter.name];
        return moreThanOneValue(currentFilter)
            ? this.changeParams(currentFilter, filter, params, defaultParams)
            : this.resetFilterIfEmpty(currentFilter, filter, params, defaultParams);
    }
    private changeParams(currentFilter: any, filter: any, params: any, defaultParams: any): [any, any] {
        return this.isPristineFilter(filter)
            ? [
                  assign(filter, { pristine: false }),
                  assign(params, {
                      [filter.name]: difference(currentFilter, defaultParams[filter.name]),
                  }),
              ]
            : [
                  filter,
                  assign(params, {
                      [filter.name]: compact(currentFilter),
                  }),
              ];
    }
    private isPristineFilter(filter: any): boolean {
        return filter.pristine !== false;
    }
    private resetFilterIfEmpty(currentFilter: any, filter: any, params: any, defaultParams: any): [any, any] {
        return currentFilter.length === 0 ? this.resetFilter(filter, params, defaultParams) : [filter, params];
    }
    private resetFilter(filter: any, params: any, defaultParams: any): [any, any] {
        return [assign(filter, { pristine: true }), assign(params, { [filter.name]: defaultParams[filter.name] })];
    }
    findChangedFilters(defaultParams: any, params: any): any {
        return reduceObject(
            (result, filter, key) => {
                const currentDefault = defaultParams[key];
                if (isArray(filter) || isObject(filter)) {
                    if (!isEqual(currentDefault, filter)) {
                        result[key] = filter;
                    }
                } else if (filter !== currentDefault) {
                    result[key] = filter;
                }
                return result;
            },
            {},
            params,
        );
    }
    buildFilterParams(
        defaultParams: any,
        params: any,
        wildcardSearch: string = null,
        selectedTags: any = null,
        rejectedTags: any = null,
        anyTags: any = null,
        accountListId: string = this.api.account_list_id,
    ): any {
        const changedFilters = this.findChangedFilters(defaultParams, params);
        const filterParams = assign(changedFilters, {
            account_list_id: accountListId,
            wildcard_search: emptyToNull(wildcardSearch),
            tags: convertTags(selectedTags),
            exclude_tags: convertTags(rejectedTags),
            any_tags: anyTags,
        });
        return omitBy(isNil, filterParams);
    }
    isResettable(params: any, defaultParams: any, tagsResettable: boolean, wildcardSearch: string): boolean {
        return !isEqual(params, defaultParams) || tagsResettable || !isEmpty(wildcardSearch);
    }
    invertMultiselect(params: any, filter: any): [any, any] {
        const reverseName = `reverse_${filter.name}`;
        if (params[reverseName]) {
            delete params[reverseName];
        } else {
            params[reverseName] = true;
        }
        filter.reverse = !!params[reverseName];
        return [params, filter];
    }
    reset(defaultParams: any, stateParams: any, filters: any[]): [any, any, any[], string, string] {
        const params = assign(angular.copy(defaultParams), this.fromStrings(stateParams, filters));
        const wildcardSearch = '';
        const selectedSave = undefined;
        const data = map(
            (filter) =>
                assign(filter, {
                    reverse: false,
                }),
            filters,
        );
        return [params, defaultParams, data, wildcardSearch, selectedSave];
    }
}

export default angular.module('mpdx.common.filters.service', [api]).service('filters', FiltersService).name;
